import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  InputAdornment,
  Drawer, Badge,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BaseURL } from "../../../constants/Baseurl";
import ProjectTableCell from "../../Common/ProjectTableCell";
import CaseAddProjectModal from "./CaseAddProjectModal";
import { CaseContext } from "../../../context/CaseContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Authorization_header } from "../../../utils/helper/Constant";
import { ProjectContext } from "../../../context/ProjectContext";
import ProjectsFilters from "../../Projects/ProjectsFilters";
import MiniTableHeader from "../../Common/MiniTableHeader";
// import FlagProjectsModal from "../../Common/FlagProjectsModal";
import { HiFilter } from "react-icons/hi";
import { areFiltersApplied } from "../../../utils/helper/AreFiltersApplied";
import CaseProjectFilters from "../../FilterComponents/CaseProjectFilter";

const styles = {
  flexBoxItem: {
    display: "flex",
    justifyContent: "space-between",
    px: 2,
  },
  label: {
    color: "#404040",
    fontSize: "14px",
    fontWeight: 600,
  },
  inputBase: {
    borderRadius: "20px",
    height: "40px",
    border: "1px solid #E4E4E4",
    pl: 1,
    width: "200px",
  },
  iconStyle: { fontSize: "17px", color: "#00A398" },
  paperStyle: {
    display: "flex",
    flexDirection: "column",
    // gap: "0 2rem",
    margin: "auto",
    maxWidth: "100%",
  },
  titleStyle: {
    textAlign: "left",
    fontWeight: 600,
    fontSize: "13px",
  },
  uploadBoxStyle: {
    border: "1px dashed #E4E4E4",
    borderWidth: "2px",
    ml: 2,
    mr: 2,
    borderRadius: "20px",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  buttonStyle: {
    mr: 1,
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#9F9F9F",
    "&:hover": { backgroundColor: "#9F9F9F" },
  },
  uploadButtonStyle: {
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#00A398",
    "&:hover": { backgroundColor: "#00A398" },
  },
  modalStyle: {
    display: "flex",
  },
  innerBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  buttonBox: {
    mt: 1,
    display: "flex",
    justifyContent: "flex-end",
    px: 2,
    mb: 2,
  },
  searchIconStyle: {
    color: "#9F9F9F",
    ml: "3px",
    mr: "-3px",
    width: "20px",
    height: "20px",
  },
  cellStyle: {
    whiteSpace: "nowrap",
    borderRight: "1px solid #ddd",
    textAlign: "left",
    fontSize: "13px",
    py: 1,
  },
  cellCurrencyStyle: {
    whiteSpace: "nowrap",
    borderRight: "1px solid #ddd",
    textAlign: "right",
    fontSize: "13px",
    py: 1,
  },
  headerRowStyle: {
    backgroundColor: "rgba(64, 64, 64, 0.1)",
  },
  topBoxStyle: {
    borderBottom: "1px solid #E4E4E4",
    px: 2.5,
    textAlign: "left",
    py: 1,
  },
};

const styleConstants = {
  filterDownloadStyle: {
    color: "white",
    borderRadius: "50%",
    backgroundColor: "#00A398",
    fontSize: "28px",
    padding: "5px",
    marginRight: "16px",
    cursor: "pointer",
  },
  tableContainerStyle: {
    borderLeft: "1px solid #E4E4E4",
  },
  overlay: {
  },
  containerDimmed: {
  },
};

const tableData = {
  columns: [
    "Project ID",
    "Project Name",
    "Project Code",
    "SPOC Name",
    "SPOC Email",
    `Total Expense`,
    "R&D Expense",
    "R&D Potential (%)"
  ],
  rows: [
    {
      id: 1,
      projectId: "",
      timesheet: "",
      month: "",
      rndHours: "",
      hourlyRate: "",
      rndExpense: "",
    },
  ],
};

const CaseProjectsTab = ({ company, currencySymbol, currency, onApplyFilters, documentType = "" }) => {
  const { fetchFilterProjectsList } = useContext(CaseContext);
  const [search, setSearch] = useState("");
  const [filteredProject, setFilteredProject] = useState([]);
  const [caseProject, setCaseProjects] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const { detailedCase, caseProjects } = useContext(CaseContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [fetchCompanyProjects, setFetchCompanyProjects] = useState(false);
  const [flagModalOpen, setFlagModalOpen] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [projectSortParams, setProjectSortParams] = useState({ sortField: null, sortOrder: null });
  const {
    projectFilterState,
    setCurrentState,
    currentState,
  } = useContext(ProjectContext);

  const fetchSortParams = ({ sortField, sortOrder }) => {
    setProjectSortParams({ sortField: sortField, sortOrder: sortOrder });
  }

  const handleFlagModalClose = () => {
    setFlagModalOpen(false);
  };

  const handleFlagModalOpen = () => {
    setFlagModalOpen(true);
  }

  const handleSubmenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const fetchAddedCaseProjects = async (filters = {}) => {
    setLoading(true);
    setCaseProjects([]);

    const payload = { headers: Authorization_header().headers };

    try {
      if (company) {
        const queryParams = new URLSearchParams();

        if (detailedCase?.caseId) queryParams.append("caseId", `"${detailedCase.caseId}"`);
        if (filters.projectNames && filters.projectNames.length > 0) {
          queryParams.append("projectNames", JSON.stringify(filters.projectNames));
        }
        if (filters.spocName?.length) queryParams.append("caseSpocNames", JSON.stringify(filters.spocName));
        if (filters.spocEmail?.length) queryParams.append("caseSpocEmails", JSON.stringify(filters.spocEmail));
        if (filters.minTotalExpense != null && filters.minTotalExpense > 0)
          queryParams.append("minTotalExpense", filters.minTotalExpense);
        if (filters.maxTotalExpense != null && filters.maxTotalExpense < 2000000)
          queryParams.append("maxTotalExpense", filters.maxTotalExpense);
        if (filters.minRnDExpense != null && filters.minRnDExpense > 0)
          queryParams.append("minRnDExpense", filters.minRnDExpense);
        if (filters.maxRnDExpense != null && filters.maxRnDExpense < 2000000)
          queryParams.append("maxRnDExpense", filters.maxRnDExpense);
        if (filters.minRnDPotential != null && filters.minRnDPotential > 0)
          queryParams.append("minRnDPotential", filters.minRnDPotential);
        if (filters.maxRnDPotential != null && filters.maxRnDPotential < 100)
          queryParams.append("maxRnDPotential", filters.maxRnDPotential);

        if (projectSortParams?.sortField) queryParams.append("sortField", projectSortParams.sortField);
        if (projectSortParams?.sortOrder) queryParams.append("sortOrder", projectSortParams.sortOrder);

        const queryString = queryParams.toString();
        const url = `${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/${company}/get-projects${queryString ? `?${queryString}` : ""}`;

        const response = await axios.get(url, payload);
        setCaseProjects(response?.data?.data?.list || []);
        setLoading(false);
      } else {
        console.error("CaseId not available in data object");
        setLoading(false);
        setCaseProjects([]);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setCaseProjects([]);
    }
  };
  useEffect(() => {
    const shouldFetchWithFiltersProjects =
      projectFilterState.companyIds?.length > 0 ||
      projectFilterState.projectNames?.length > 0 ||
      projectFilterState.spocName?.length > 0 ||
      projectFilterState.spocEmail?.length > 0 ||
      projectFilterState.accountingYear?.length > 0 ||
      projectFilterState.totalefforts?.length > 0 ||
      projectFilterState.rndExpense?.length > 0 ||
      projectFilterState.rndPotential?.length > 0;

    let options = {
      ...(projectFilterState.companyId?.length > 0 && { client: projectFilterState.companyId }),
      ...(projectFilterState.projectNames?.length > 0 && { projectNames: projectFilterState.projectNames }),
      ...(projectFilterState.spocName?.length > 0 && { spocName: projectFilterState.spocName }),
      ...(projectFilterState.spocEmail?.length > 0 && { spocEmail: projectFilterState.spocEmail }),
      ...(projectFilterState.totalefforts && { minTotalExpense: projectFilterState.totalefforts[0] }),
      ...(projectFilterState.totalefforts && { maxTotalExpense: projectFilterState.totalefforts[1] }),
      ...(projectFilterState.rndExpense && { minRnDExpense: projectFilterState.rndExpense[0] }),
      ...(projectFilterState.rndExpense && { maxRnDExpense: projectFilterState.rndExpense[1] }),
      ...(projectFilterState.rndPotential && { minRnDPotential: projectFilterState.rndPotential[0] }),
      ...(projectFilterState.rndPotential && { maxRnDPotential: projectFilterState.rndPotential[1] }),
    };

    fetchAddedCaseProjects(options);
  }, [currentState, projectSortParams]);

  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      fetchAddedCaseProjects(filters);
    } else {
      toast.error("Please select at least one filter.");
    }
  };
  const appliedFilters = {
    company: projectFilterState.company,
  };
  const handleRemoveProject = async (projectId) => {
    try {
      const response = await axios.put(
        `${BaseURL}/api/v1/case/${localStorage.getItem(
          "userid"
        )}/projects/${projectId}/remove`, {}, Authorization_header()
      );
      if (response?.data?.success) fetchAddedCaseProjects();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFlagProject = async (projectId, caseFlag) => {
    try {
      const response = await axios.post(
        `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/caseFlag`,
        {
          projectId: projectId,
          caseId: detailedCase?.caseId,
          caseFlag: !caseFlag,
        }, Authorization_header()
      );
      fetchAddedCaseProjects();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setPage(0);
    // fetchAddedCaseProjects();
    // fetchFilterProjectsList();
  }, [detailedCase?.caseId]);

  const handleFilterClick = () => {
    setFilterClicked(!filterClicked);
    setDrawerOpen(!drawerOpen);
    setFilterPanelOpen(!filterPanelOpen);
  };

  const handleFilterPanelClose = () => {
    setFilterPanelOpen(false);
    setTimeout(() => {
      setDrawerOpen(false);
      setFilterClicked(false);  // Reset the table movement after drawer closes
    }, 0);  // Timeout to match the drawer's closing transition duration
  };

  const handleFilterClose = () => {
    setFilterPanelOpen(false);
  };

  const countActiveFilters = () => {
    let count = 0;

    // Count selected companies if any
    if (projectFilterState?.company?.length > 0) count += 1;

    // Count selected accounting years if any
    if (Array.isArray(projectFilterState?.projectNames)) {
      if (projectFilterState.projectNames.some(projectNames => projectNames?.trim() !== "")) {
        count += 1;
      }
    }

    // Ensure spocName is an array and count non-empty names
    if (Array.isArray(projectFilterState?.spocName)) {
      if (projectFilterState.spocName.some(spocName => spocName?.trim() !== "")) {
        count += 1;
      }
    }

    // Ensure spocEmail is an array and count non-empty emails
    if (Array.isArray(projectFilterState?.spocEmail)) {
      if (projectFilterState.spocEmail.some(spocEmail => spocEmail?.trim() !== "")) {
        count += 1;
      }
    }

    // Count total expenses greater than 0
    if (Array.isArray(projectFilterState?.totalefforts)) {
      if (projectFilterState.totalefforts.some(expense => expense > 0)) {
        count += 1;
      }
    }

    // Count rnd expenses greater than 0
    if (Array.isArray(projectFilterState?.rndExpense)) {
      if (projectFilterState.rndExpense.some(expense => expense > 0)) {
        count += 1;
      }
    }

    // Count rnd potential greater than 0
    if (Array.isArray(projectFilterState?.rndPotential)) {
      if (projectFilterState.rndPotential.some(potential => potential > 0)) {
        count += 1;
      }
    }

    return count;
  };

  useEffect(() => {
    if (caseProjects) {
      const searchText = search?.trim();
      const filteredData = caseProjects?.filter(
        (task) =>
          task?.projectName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          (task?.projectId + "")?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          (task?.projectCode + "")?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.projectManager?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.spocName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.spocEmail?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.totalEfforts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalCosts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalRnDEfforts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalRnDCosts?.toString()?.includes(search?.toLowerCase())
      );
      setFilteredProject(filteredData);
      setPage(0);
    }
  }, [caseProjects, search]);

  useEffect(() => {
    if (caseProject) {
      const searchText = search?.trim();
      const filteredData = caseProject?.filter(
        (task) =>
          task?.projectName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          (task?.projectId + "")?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          (task?.projectCode + "")?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.projectManager?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.spocName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.spocEmail?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.totalEfforts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalCosts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalRnDEfforts?.toString()?.includes(search?.toLowerCase()) ||
          task?.totalRnDCosts?.toString()?.includes(search?.toLowerCase())
      );
      setFilteredProject(filteredData);
      setPage(0);
    }
  }, [caseProject, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalClose = () => {
    setOpen(!open);
  };

  const handleModalClose1 = () => {
    setModalOpen(false);
  };
  return (
    <>
      <CaseAddProjectModal
        open={open}
        company={company}
        handleClose={handleModalClose}
        fetchAddedCaseProjects={fetchAddedCaseProjects}
        fetchCompanyProjects={fetchCompanyProjects}
        currencySymbol={currencySymbol}
        currency={currency}
      />
      <Paper
        sx={{ ...styles.paperStyle, ...(!open ? { display: "none" } : {}) }}
      >
        <Box
          sx={{
            px: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0.3rem 0.3rem",
          }}
        >
          <Box sx={{ display: "flex", pt: 0, pb: page === "activity" ? -1 : 0 }}>
            <Box sx={{ marginLeft: "1px", marginTop: "-7px", display: "flex", alignItems: "center" }}>
              {!(page === "alerts") && (
                <Badge
                  badgeContent={countActiveFilters()}
                  color="error"
                  overlap="circular"
                  sx={{
                    zIndex: 2,
                    marginRight: "0px",
                    '& .MuiBadge-badge': {
                      minWidth: '10px',
                      height: '16px',
                      fontSize: '10px',
                      paddingLeft: '5',
                      transform: 'translate(25%, -25%)',
                      backgroundColor: '#FD5707',
                    },
                  }}
                >
                  <HiFilter
                    style={styleConstants.filterDownloadStyle}
                    onClick={handleFilterClick}
                  />
                </Badge>
              )}
            </Box>
            <InputLabel sx={styles.label}>Case Projects</InputLabel>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleFilterPanelClose}
              sx={{
                width: '300px',
                flexShrink: 0,
              }}
              variant="persistent"
            >
              {filterPanelOpen && (
                <CaseProjectFilters
                  handleClose={handleFilterPanelClose}
                  open={filterPanelOpen}
                  page={page}
                  documentType={documentType}
                  onApplyFilters={applyFiltersAndFetch}
                  style={{ position: 'absolute', left: 0 }}
                />
              )}
            </Drawer>
          </Box>
          <InputBase
            type="text"
            value={search}
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={styles.searchIconStyle} />
              </InputAdornment>
            }
            sx={{
              ...styles.inputBase,
              width: "30%",
              alignItems: "right",
              mr: -32,
            }}
          />
          <Box sx={{ display: "flex", gap: "2rem" }}>
            <Button
              variant="contained"
              sx={styles.uploadButtonStyle}
              onClick={() => handleModalClose()}
            >
              + Projects
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: drawerOpen ? '300px' : '0',
            px: 2,
          }}
        >
          <TableContainer sx={{
            maxHeight: "82vh",
            overflowY: "auto",
            borderTopLeftRadius: "20px",
            height: 300,
          }}>
            <Table stickyHeader aria-label="simple table">
              {/* <TableHead>
                <TableRow sx={styles?.headerRowStyle}>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Project Id
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Project Name
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Project Code
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    SPOC Name
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    SPOC Email
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Total Efforts(Hrs)
                  </TableCell> 
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "right",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    {`Total Cost (${currencySymbol} ${currency})`}

                  </TableCell>
                  {/* <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    Total R&D Efforts(Hrs)
                  </TableCell> 
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    {`R&D Cost (${currencySymbol} ${currency})`}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles?.cellStyle,
                      textAlign: "left",
                      backgroundColor: "#ECECEC",
                    }}
                  >
                    R&D Potential(%)
                  </TableCell>
                </TableRow>
              </TableHead> */}
              <MiniTableHeader tableData={tableData} fetchSortParams={fetchSortParams} />
              <TableBody>
                {filteredProject
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, rowIndex) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row?.projectId + row?.FirstName}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: row?.flag ? "#F4C4AE" : "",
                        }}
                      >
                        <ProjectTableCell
                          id={row?.projectId}
                          name={`${row?.projectId || ""}`}
                        />
                        <ProjectTableCell
                          id={row?.projectId}
                          name={`${row?.projectName}`}
                        />
                        <ProjectTableCell
                          id={row?.projectId}
                          name={`${row?.projectCode || ""}`}
                        />
                        <TableCell sx={{ ...styles?.cellStyle, textAlign: "left" }}>
                          {(row?.spocName || "")}
                        </TableCell>
                        <TableCell sx={{ ...styles?.cellStyle, textAlign: "left" }}>
                          {(row?.spocEmail || "")}
                        </TableCell>
                        {/* <TableCell sx={styles?.cellStyle}>
                          {row?.totalefforts ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.totalefforts) : ""}
                        </TableCell> */}
                        <TableCell sx={styles?.cellCurrencyStyle}>
                          {row?.totalCosts ? `${currencySymbol}` + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.totalCosts) : ""}
                        </TableCell>
                        {/* <TableCell sx={styles?.cellStyle}>
                          {row?.totalRnDEfforts ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.totalRnDEfforts) : ""}
                        </TableCell> */}
                        <TableCell sx={styles?.cellCurrencyStyle}>
                          {row?.rndExpense ? `${currencySymbol}` + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.rndExpense) : ""}
                        </TableCell>
                        <TableCell sx={styles?.cellCurrencyStyle}>
                          {row?.rndPotential ? `${currencySymbol}` + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(row?.rndPotential) : ""}
                        </TableCell>
                        {/* <TableCell sx={styles?.cellStyle}>
                          <MoreVertIcon
                            sx={{
                              color: "#9F9F9F",
                              cursor: "pointer",
                              transform:
                                selectedIndex !== rowIndex
                                  ? "none"
                                  : "rotate(90deg)",
                            }}
                            onClick={(event) =>
                              handleSubmenuClick(event, rowIndex)
                            }
                          />
                          <Menu
                            anchorEl={anchorEl}
                            open={
                              Boolean(anchorEl) && selectedIndex === rowIndex
                            }
                            onClose={handleAnchorClose}
                          >
                            {row?.surveyCode &&
                              <MenuItem onClick={handleAnchorClose}>
                                {row?.surveyCode}
                              </MenuItem>
                            }
                            <MenuItem
                              onClick={() =>
                                handleRemoveProject(row?.caseProjectId)
                              }
                            >
                              Remove Project
                            </MenuItem>
                          </Menu>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                <CircularProgress sx={{ color: "#00A398" }} />
              </div>
            )}
            {filteredProject.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  minHeight: "380px",
                }}
              >
                No projects found.
              </div>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredProject?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        {/* <FlagProjectsModal open={flagModalOpen} handleClose={handleFlagModalClose} /> */}
        <Toaster />
      </Paper>
    </>
  );
};

export default CaseProjectsTab;
