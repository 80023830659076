import React, { useContext, useEffect, useState } from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ClientContext } from "../../context/ClientContext";
import { ProjectContext } from "../../context/ProjectContext";
import { TimesheetContext } from "../../context/TimesheetContext";
import { CaseContext } from "../../context/CaseContext";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import StraightIcon from '@mui/icons-material/Straight';
import { ContactContext } from "../../context/ContactContext";

const headerCellStyle = {
  fontSize: "13px",
  borderRight: "1px solid #ddd",
  borderLeft: "1px solid #ddd",
  whiteSpace: "nowrap",
  py: 1,
  textAlign: "left",
  position: "sticky",
  top: 0,
  zIndex: 10,
  backgroundColor: "#ececec",
  cursor: "pointer",
};

const headerRowStyle = {
  backgroundColor: "rgba(64, 64, 64, 0.1)",
  position: "sticky",
  top: 0,
  zIndex: 10,
  borderLeft: "1px solid #E4E4E4",
};

// Define colors
const activeColor = "#404040"; // Darker color for active arrow
const inactiveColor = "#ccc"; // Lighter color for inactive arrow

function TableHeader({ tableData, tableData2, page }) {
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const { getAccountsSortParams } = useContext(ClientContext);
  const { getProjectsSortParams, fetchProjects } = useContext(ProjectContext);
  const { getTimeSheetsortParams, selectedTimeSheetId } = useContext(TimesheetContext);
  const { getCaseSortParams } = useContext(CaseContext);
  const { getEmployeeSortParams } = useContext(ContactContext);


  useEffect(() => {
    if (page === "accounts") getAccountsSortParams({ sortField, sortOrder });
    if (page === "projects") getProjectsSortParams({ sortField, sortOrder });
    if (page === "timeSheet") getTimeSheetsortParams({ sortField, sortOrder });
    if (page === "cases") getCaseSortParams({ sortField, sortOrder });
    if (page === "contacts") getEmployeeSortParams({ sortField, sortOrder });
    if (page === "project_details") {
      // getProjectsSortParams({ sortField, sortOrder });
      const options = {};
      if (selectedTimeSheetId) {
        options.timesheetId = selectedTimeSheetId;
        options.sortField = sortField;
        options.sortOrder = sortOrder;
      }
      fetchProjects(options);
    }
  }, [sortField, sortOrder, page]);

  const handleColumnClick = (column) => {
    if (sortField === column) {
      if (sortOrder === "asc") {
        setSortOrder("dsc");
      } else if (sortOrder === "dsc") {
        setSortOrder(null);
        setSortField(null);
      } else {
        setSortOrder("asc");
      }
    } else {
      setSortField(column);
      setSortOrder("asc");
    }
  };

  const renderSortIcons = (column) => {
    let upColor = activeColor;  // Default to active color initially
    let downColor = activeColor;  // Default to active color initially

    // Check if the current column is being sorted
    if (sortField === column) {
      if (sortOrder === "asc") {
        // Ascending sort: highlight upward arrow with #00A398, set downward arrow to inactive
        downColor = "#FD5707";
        upColor = inactiveColor;
      } else if (sortOrder === "dsc") {
        // Descending sort: highlight downward arrow with #00A398, set upward arrow to inactive
        upColor = "#FD5707";
        downColor = inactiveColor;
      }
    }

    return (
      <>
        <StraightIcon
          fontSize="small"
          style={{ color: upColor, opacity: 0.6, marginRight: -5, fontSize: "17px" }}
        />
        <StraightIcon
          fontSize="small"
          style={{
            color: downColor,
            opacity: 0.6,
            marginLeft: -5,
            fontSize: "17px",
            transform: "rotate(180deg)", // Rotates the icon by 180 degrees
          }}
        />
      </>
    );
  };



  return (
    <TableHead>
      <TableRow sx={headerRowStyle}>
        {tableData?.columns?.map((column, index) => (
          <TableCell
            key={index}
            sx={{
              ...headerCellStyle,
              textAlign: index === 0 ? "left" : "center",
            }}
            onClick={() => handleColumnClick(column)}
          >
            {column}

            <IconButton size="small">
              {renderSortIcons(column)}
            </IconButton>

          </TableCell>
        ))}
        {tableData2?.columns?.map((column, index) => (
          <TableCell
            key={index}
            sx={{
              ...headerCellStyle,
              textAlign: index === 0 ? "left" : "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => handleColumnClick(column)}
          >
            {column}

            <IconButton size="small">
              {renderSortIcons(column)}
            </IconButton>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
