import React, { useEffect, useState } from "react";
import { Box, InputAdornment, InputBase, Table, TableContainer } from "@mui/material";
import UpdationDetails2 from "../Common/UpdationDetails2";
import MiniTableHeader from "../Common/MiniTableHeader";
import { HiFilter } from "react-icons/hi";
import ContactsRndTableBody from "./ContactsRndTableBody";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import SearchIcon from "@mui/icons-material/Search";

const tableData = {
  columns: [
    "Project Name",
    "Project ID",
    "Total Hours",
    "Hourly Rate",
    "R&D Expense",

  ],
  rows: [
    {
      id: 1,
      projectId: "",
      timesheet: "",
      month: "",
      rndHours: "",
      hourlyRate: "",
      rndExpense: "",
    },
  ],
};

const styleConstants = {
  inputStyle: {
    borderRadius: "20px",
    width: "30%",
    height: "40px",
    border: "1px solid #9F9F9F",
    mr: 2,
  },
  searchIconStyle: {
    color: "#9F9F9F",
    ml: "3px",
    mr: "-3px",
    width: "20px",
    height: "20px",
  },
}

const filterIcon = {
  color: "white",
  borderRadius: "50%",
  backgroundColor: "#00A398",
  fontSize: "32px",
  padding: "5px",
  marginRight: "16px",
};

const RndExpenseTab = ({ data, modifiedBy, latestUpdateTime, getrndSortParams }) => {
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const handleSearchInputChange = (event) => {
    setSearch(event?.target?.value);
  }

  useEffect(() => {
    const filteredData = data?.filter(task => (
      task?.projectName?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.projectCode?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.projectId?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.projectRole?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.totalRndHours?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.hourlyRate?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.rndExpense?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.employeeTitle?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) 
      // task?.rndPotential?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) 
      // formatFyscalYear(task?.accountingYear)?.toString().toLowerCase().includes(search?.toString()?.toLowerCase())
    ))
    setFilteredRows(filteredData);
  }, [search, data])
  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid #E4E4E4",
          p: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UpdationDetails2
            items={data?.length}
            latestUpdateTime={latestUpdateTime}
            modifiedBy={modifiedBy}
          />
          <InputBase
            type="text"
            placeholder="Search..."
            onChange={handleSearchInputChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={styleConstants.searchIconStyle} />
              </InputAdornment>
            }
            sx={styleConstants.inputStyle}
          />
          {/* {useHasAccessToFeature("F036", "P000000009") && (
            <HiFilter style={filterIcon} />
          )} */}
        </Box>
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",
            maxHeight: "50vh",
            overflowX: "auto",
            // scrollbarWidth: "none",
            // msOverflowStyle: "none",
            // "&::-webkit-scrollbar": {
            //   display: "none",
            // },
          }}
        >
          <Table stickyHeader aria-label="simple table">
            <MiniTableHeader tableData={tableData} fetchSortParams={getrndSortParams} />
            <ContactsRndTableBody filledRows={filteredRows} />
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default RndExpenseTab;
