import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const styles = {
  boxStyle: {
    p: 0,
    borderTop: "1px solid #E4E4E4",
  },
  tableStyle: {
    minWidth: 650,
    p: 0,
  },
  tableHeadCell: {
    border: "none",
    paddingBottom: 0,
    fontWeight: 600,
    fontSize: "13px",
  },
  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    paddingTop: "1px"
  },
  tableCell: {
    fontSize: "13px",
    paddingTop: "1px"
  },
  tableCell1: {
    fontSize: "13px",
    color: "#00A398",
    paddingTop: "1px"
  },
  tableCell2: {
    fontSize: "13px",
    color: "#FD5707",
    paddingTop: "1px"
  },
};

function CompanyInfoboxTable({ info }) {
  return (
    <>
      <Box sx={styles.boxStyle}>
        <TableContainer>
          <Table sx={styles.tableStyle} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeadCell}>Accounts ID</TableCell>
                <TableCell sx={styles.tableHeadCell}>Primary Contact</TableCell>
                <TableCell sx={styles.tableHeadCell}>Projects</TableCell>
                <TableCell sx={styles.tableHeadCell}>Industry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.tableCell1}>{info?.companyIdentifier}</TableCell>
                <TableCell sx={styles.tableCell}>
                  {info?.primaryContact}
                </TableCell>
                <TableCell sx={styles.tableCell2}>
                  {info?.projectsCount}
                </TableCell>
                <TableCell sx={styles.tableCell}>{info?.industry}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default CompanyInfoboxTable;
