import React, { useEffect, useState } from 'react';
import { BaseURL } from "../constants/Baseurl";
import axios from 'axios';
import FormatDatetime from '../utils/helper/FormatDatetime';
import { Authorization_header } from '../utils/helper/Constant';
import { useAuthContext } from './AuthProvider';
import toast from 'react-hot-toast';
import usePinnedData from '../components/CustomHooks/usePinnedData';


export const CaseContext = React.createContext();

export const CaseContextProvider = ({ children }) => {
  const [caseData, setCaseData] = useState([]);
  const [cases, setCase] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);
  const [detailedCase, setDetailedCase] = useState(null);
  const [reqCaseId, setReqCaseId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [clearCaseFilterTrigger, setClearCaseFilterTrigger] = useState(false);
  const [isCaseFilterApplied, setIsCaseFilterApplied] = useState(false);
  const { logout } = useAuthContext();
  const { pinnedObject } = usePinnedData();
  const [sortParams, setSortPrams] = useState({ sortField: null, sortOrder: null });
  const [projectSortParams, setProjectSortParams] = useState({ sortField: null, sortOrder: null });
  const [filterCaseSurveysList, setCaseSurveysList] = useState([]);
  const [unsentSurveyList, setUnsentSurveyList] = useState([]);
  const [caseFilterOptions, setCaseFilterOptions] = useState({});
  const [caseFilterFields, setCaseFilterFields] = useState("");
  const [caseSortFields, setCaseSortFields] = useState("");
  const [caseProjects, setCaseProjects] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [interactionFilterData, setInteractionData] = useState([]);
  const [loader, setLoader] = useState(false);
  // const [caseFilterOptions]
  const [currentState, setCurrentState] = useState(
    pinnedObject?.CASES === 'RV' ? 'Recently Viewed' : 'All Cases'
  );
  const [caseFilterState, setCaseFilterState] = useState({
    projectNames: [],
    projectId: [],
    companyId: [],
    company: [],
    sortField: '',
    sortOrder: '',
    countryName: [],
    caseOwners: [],
  });
  const getCaseSortParams = ({ sortField, sortOrder }) => {

    setSortPrams({
      sortField,
      sortOrder
    })
  }

  const fetchSortParams = ({ sortField, sortOrder }) => {
    setProjectSortParams({ sortField: sortField, sortOrder: sortOrder });
  }

  const triggerCaseClearFilters = () => {
    setCaseFilterState({
      project: [],
      projectId: [],
      companyId: [],
      company: [],
      sortField: '',
      sortOrder: '',
      countryName: [],
      caseOwners: [],
    });
    setClearCaseFilterTrigger((prev) => !prev);
  };

  useEffect(() => {
    if (clearCaseFilterTrigger) {
      getAllCases();
    }
  }, [clearCaseFilterTrigger]);

  useEffect(() => {
    if (clearCaseFilterTrigger) {
      fetchFilterProjectsList();
    }
  }, [clearCaseFilterTrigger]);

  useEffect(() => {
    fetchAllCases();
  }, [sortParams])

  const getCaseById = async (id) => {
    try {
      const response1 = await axios.get(`${BaseURL}/api/v1/case/${localStorage.getItem(
        "userid"
      )}/${id}/casedetails`, Authorization_header());
      const singleCase = response1?.data?.data?.casedetails;
      setDetailedCase(singleCase);
    } catch (error) {
      if (error?.response?.data?.logout === true) {
        logout();
      }
      console.error("Error fetching case by ID:", error);
    }
  };

  function getAccessToken() {
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return token_obj?.accessToken || '';
  }
  const caseSort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/cases`;
    try {
      toast.loading("Fetching case data");
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
      };
      const response = await axios.get(url, config)
      const caseList = response?.data?.data?.caseDetails?.list?.map((elemnt) => ({
        caseCode: elemnt.caseCode,
        caseId: elemnt.caseId,
        status: elemnt.status,
        caseType: elemnt.caseType,
        companyId: elemnt.companyId,
        companyName: elemnt.companyName,
        countryName: elemnt.countryName,
        contactId: elemnt.primaryContactId,
        contactName: elemnt.primaryContactName,
        caseRoleName: elemnt.caseRoleName,
        caseOwnerName: elemnt?.caseOwnerName,
        email: elemnt.primaryContactEmail,
        caseTypeDescription: elemnt.caseTypeDescription,
        createdOn: FormatDatetime(elemnt?.createdOn).split("T")[0].split(' ')[0],
      }))
      setCaseData(caseList);
      setCaseFilterFields(response?.data?.data?.caseDetails?.appliedFilter);
      setCaseSortFields(response?.data?.data?.caseDetails?.appliedSort);
      toast.dismiss();

      toast.success(response?.data?.message || "Succesfully fetched data");
    } catch (error) {
      toast.dismiss();
      toast.error("Error in fetching Timesheet data");
      console.error("Error in fetching client data : ", error);
    }
  }

  const fetchAllCases = async () => {
    const queryParams = new URLSearchParams();
    try {
      // if (caseFilterOptions?.client || caseFilterState?.companyId?.length > 0) {
      //   queryParams.append("companyIds", JSON.stringify(caseFilterOptions?.client || caseFilterState?.companyId));
      // }
      // if (caseFilterOptions?.countryName) {
      //   queryParams.append("countryName", caseFilterOptions?.countryName);
      // }
      if (Array.isArray(caseFilterOptions?.companyId) && caseFilterOptions.companyId?.length > 0) {
        queryParams.append("companyIds", JSON.stringify(caseFilterOptions.companyId));
      }
      if (caseFilterOptions?.countryName && caseFilterOptions.countryName?.length > 0)
        queryParams.append("countryName", JSON.stringify(caseFilterOptions.countryName));

      if (caseFilterOptions?.caseOwners && caseFilterOptions.caseOwners?.length > 0)
        queryParams.append("caseOwners", JSON.stringify(caseFilterOptions.caseOwners));

      if (sortParams?.sortField && sortParams?.sortOrder) {
        queryParams.append("sortField", sortParams?.sortField);
      }
      if (sortParams?.sortOrder && sortParams?.sortOrder) {
        queryParams.append("sortOrder", sortParams?.sortOrder);
      }
      const url = `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/cases${queryParams ? `?${queryParams}` : ""}`;
      const response = await axios.get(url, Authorization_header());
      const caseList = response?.data?.data?.caseDetails?.list?.map((elemnt) => ({
        caseCode: elemnt.caseCode,
        caseId: elemnt.caseId,
        status: elemnt.status,
        caseType: elemnt.caseType,
        companyId: elemnt.companyId,
        companyName: elemnt.companyName,
        countryName: elemnt.countryName,
        contactId: elemnt.primaryContactId,
        contactName: elemnt.primaryContactName,
        caseRoleName: elemnt.caseRoleName,
        caseOwnerName: elemnt?.caseOwnerName,
        email: elemnt.primaryContactEmail,
        caseTypeDescription: elemnt.caseTypeDescription,
        createdOn: FormatDatetime(elemnt?.createdOn).split("T")[0].split(' ')[0],
      }));
      setCaseData(caseList);
      setCaseFilterFields(response?.data?.data?.caseDetails?.appliedFilter);
      setCaseSortFields(response?.data?.data?.caseDetails?.appliedSort);
      setLoading(false);
      return caseList;
    } catch (error) {
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      setLoading(false);
      // toast.error("Error fetching cases");
      console.error("Error in fetching cases: ", error);
    }
  }

  const getAllCases = async (options = {}) => {
    setCaseFilterOptions(options);
    // setLoading(true);
    // if (params) {
    //   setCasefilterParams(params);
    // }
    // if (options) {
    //   setCaseFilterOptions(options);
    // }
    try {
      // Build query string from params
      const queryParams = new URLSearchParams();
      if (options.companyId && options.companyId?.length > 0)
        queryParams.append("companyIds", JSON.stringify(options.companyId));

      if (options.countryName && options.countryName?.length > 0)
        queryParams.append("countryName", JSON.stringify(options.countryName));

      if (options.caseOwners && options.caseOwners?.length > 0)
        queryParams.append("caseOwners", JSON.stringify(options.caseOwners));

      if (options?.sortField) {
        queryParams.append("sortField", options.sortField);
      }

      if (options?.sortOrder) {
        queryParams.append("sortOrder", options.sortOrder);
      }

      if (sortParams?.sortField && sortParams?.sortOrder) {
        queryParams.append("sortField", sortParams?.sortField);
      }

      if (sortParams?.sortOrder && sortParams?.sortOrder) {
        queryParams.append("sortOrder", sortParams?.sortOrder);
      }
      const url = `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/cases${queryParams ? `?${queryParams}` : ""}`;

      const response = await axios.get(url, Authorization_header());
      const caseList = response?.data?.data?.caseDetails?.list?.map((elemnt) => ({
        caseCode: elemnt.caseCode,
        caseId: elemnt.caseId,
        status: elemnt.status,
        caseType: elemnt.caseType,
        companyId: elemnt.companyId,
        companyName: elemnt.companyName,
        countryName: elemnt.countryName,
        contactId: elemnt.primaryContactId,
        contactName: elemnt.primaryContactName,
        caseRoleName: elemnt.caseRoleName,
        caseOwnerName: elemnt?.caseOwnerName,
        email: elemnt.primaryContactEmail,
        caseTypeDescription: elemnt.caseTypeDescription,
        createdOn: FormatDatetime(elemnt?.createdOn).split("T")[0].split(' ')[0],
      }));
      setCaseData(caseList);
      setCaseFilterFields(response?.data?.data?.caseDetails?.appliedFilter);
      setCaseSortFields(response?.data?.data?.caseDetails?.appliedSort);
      setLoading(false);
      return caseList;
    } catch (error) {
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      setLoading(false);
      // toast.error("Error fetching cases");
      console.error("Error in fetching cases: ", error);
    }
  };

  const getCaseOnLanding = async (options = {}) => {
    const queryParams = new URLSearchParams(window.location.search);
    if (options.client || caseFilterState?.companyId?.length > 0) {
      queryParams.append("companyIds", JSON.stringify(options.client || caseFilterState.companyId));
    }
    if (options.countryName) {
      queryParams.append("countryName", options.countryName);
    } options.sortField && queryParams.append("sortField", options.sortField);
    options.sortOrder && queryParams.append("sortOrder", options.sortOrder);

    const value = queryParams.get('caseId');
    setReqCaseId(value);

    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/case/${localStorage.getItem(
      "userid"
    )}/cases${queryString ? `?${queryString}` : ""}`;

    setIsCaseFilterApplied(Boolean(queryString));

    try {
      const response = await axios.get(url, Authorization_header());
      setCase(response?.data?.data?.list);
      setLoading(false);
    } catch (error) {
      console.error("error", error);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      setLoading(false);
      console.error(error);
    }
  }

  const handleSelectedCase = (c) => {
    setSelectedCase(c);
  }
  useEffect(() => {
  }, [caseProjects]);

  const fetchFilterProjectsList = async (filters = {}) => {
    setCaseFilterState(filters);

    // Initialize the payload and queryParams
    const payload = { headers: Authorization_header().headers };
    const queryParams = new URLSearchParams();

    try {
      // Apply filters
      if (filters.caseId && filters.caseId.length > 0) {
        queryParams.append("caseId", JSON.stringify(filters.caseId));
      }

      if (filters.projectNames && filters.projectNames.length > 0) {
        queryParams.append("projectNames", JSON.stringify(filters.projectNames));
      }

      if (filters.spocName && filters.spocName.length > 0) {
        queryParams.append("caseSpocNames", JSON.stringify(filters.spocName));
      }

      if (filters.spocEmail && filters.spocEmail.length > 0) {
        queryParams.append("caseSpocEmails", JSON.stringify(filters.spocEmail));
      }

      if (filters.minTotalExpense != null && filters.minTotalExpense > 0) {
        queryParams.append("minTotalExpense", filters.minTotalExpense);
      }

      if (filters.maxTotalExpense != null && filters.maxTotalExpense < 2000000) {
        queryParams.append("maxTotalExpense", filters.maxTotalExpense);
      }

      if (filters.minRnDExpense != null && filters.minRnDExpense > 0) {
        queryParams.append("minRnDExpense", filters.minRnDExpense);
      }

      if (filters.maxRnDExpense != null && filters.maxRnDExpense < 2000000) {
        queryParams.append("maxRnDExpense", filters.maxRnDExpense);
      }

      if (filters.minRnDPotential != null && filters.minRnDPotential > 0) {
        queryParams.append("minRnDPotential", filters.minRnDPotential);
      }

      if (filters.maxRnDPotential != null && filters.maxRnDPotential < 100) {
        queryParams.append("maxRnDPotential", filters.maxRnDPotential);
      }

      // Apply sorting (from projectSortParams)
      if (projectSortParams?.sortField) {
        queryParams.append("sortField", projectSortParams.sortField);
      }

      if (projectSortParams?.sortOrder) {
        queryParams.append("sortOrder", projectSortParams.sortOrder);
      }

      const queryString = queryParams.toString();
      const url = `${BaseURL}/api/v1/projects/damy/dammy/get-projects${queryString ? `?${queryString}` : ""}`;

      // Fetch projects
      const response = await axios.get(url, payload);
      setCaseProjects(response?.data?.data?.list || []);
      setCaseFilterFields(response?.data?.data?.appliedFilter);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFilterProjectsList();
  }, [detailedCase?.caseId, projectSortParams]);


  const fetchSurveyList = async (filters = {}) => {
    setLoading(true);
    try {

      const queryParams = new URLSearchParams();
      if (filters.projectNames && filters.projectNames?.length > 0)
        queryParams.append("caseProjectNames", JSON.stringify(filters.projectNames));
      if (filters.caseId && filters.caseId?.length > 0) {
        queryParams.append("caseId", JSON.stringify(filters.caseId));
      } if (filters.status && filters.status?.length > 0)
        queryParams.append("status", JSON.stringify(filters.status));
      if (filters.sentBy && filters.sentBy?.length > 0)
        queryParams.append("sentBy", JSON.stringify(filters.sentBy));
      if (filters.sentTo && filters.sentTo?.length > 0)
        queryParams.append("sentTo", JSON.stringify(filters.sentTo));
      if (filters.sentStartDate && filters.sentStartDate?.length > 0)
        queryParams.append("sentStartDate", (filters.sentStartDate));
      if (filters.sentEndDate && filters.sentEndDate?.length > 0)
        queryParams.append("sentEndDate", (filters.sentEndDate));
      if (filters.responseReceivedStartDate && filters.responseReceivedStartDate?.length > 0)
        queryParams.append("responseReceivedStartDate", JSON.stringify(filters.responseReceivedStartDate));
      if (filters.responseReceivedStartDate && filters.responseReceivedStartDate?.length > 0)
        queryParams.append("responseReceivedStartDate", JSON.stringify(filters.responseReceivedStartDate));
      const queryString = queryParams.toString();
      const response = await axios.get(
        `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/${detailedCase.caseId}/surveylist${queryString ? `?${queryString}` : ""}`, Authorization_header()
      );

      setCaseSurveysList(response?.data?.data.data);
      setUnsentSurveyList(response?.data?.data?.unSentSurveys || []);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setCaseSurveysList([]);
    }
  }

  useEffect(() => {
    fetchSurveyList();
  }, [detailedCase?.caseId]);

  const getSummaryListing = async (filters = {}) => {
    // let url_suffix;
    // if (usedfor == 'case') {
    //   url_suffix = `caseId=${detailedCase?.caseId}`;
    // } else if (usedfor == 'project') {
    //   url_suffix = `projectIdentifier=${detailedCase?.projectId}`;
    // }
    // setLoader(true);
    try {
      const queryParams = new URLSearchParams();
      if (filters.projectNames && filters.projectNames?.length > 0)
        queryParams.append("caseProjectNames", JSON.stringify(filters.projectNames));
      if (filters.projectCodes && filters.projectCodes?.length > 0)
        queryParams.append("caseProjectCodes", JSON.stringify(filters.projectCodes));
      if (detailedCase?.caseId && detailedCase?.caseId?.length > 0) {
        queryParams.append("caseId", detailedCase?.caseId);
      } if (filters.caseProjectCodes && filters.caseProjectCodes?.length > 0)
        queryParams.append("caseProjectCodes", JSON.stringify(filters.caseProjectCodes));
      if (filters.createdOnStartDate && filters.createdOnStartDate?.length > 0)
        queryParams.append("createdOnStartDate", (filters.createdOnStartDate));
      if (filters.createdOnEndDate && filters.createdOnEndDate?.length > 0)
        queryParams.append("createdOnEndDate", (filters.createdOnEndDate));
      const queryString = queryParams.toString();
      const response = await axios.get(`${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/summary-list?${queryString ? `${queryString}` : ""}`, Authorization_header());
      setSummaryData(response?.data?.data || []);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getSummaryListing();
  }, [detailedCase?.caseId]);


  const getAllInteractions = async (filters = {}) => {
    // let url_suffix;
    // if (usedfor == 'case') {
    //   url_suffix = `caseId=${detailedCase?.caseId}`;
    // } else if (usedfor == 'project') {
    //   url_suffix = `projectIdentifier=${detailedCase?.projectId}`;
    // }
    // setLoader(true);
    try {
      const queryParams = new URLSearchParams();
      if (filters.projectNames && filters.projectNames?.length > 0)
        queryParams.append("caseProjectNames", JSON.stringify(filters.projectNames));
      if (detailedCase?.caseId && detailedCase?.caseId?.length > 0) {
        queryParams.append("caseId", detailedCase?.caseId);
      }
      if (filters.status && filters.status?.length > 0)
        queryParams.append("status", JSON.stringify(filters.status));
      if (filters.sentTo && filters.sentTo?.length > 0)
        queryParams.append("sentTo", (filters.sentTo));
      if (filters.sentStartDate && filters.sentStartDate?.length > 0)
        queryParams.append("sentStartDate", (filters.sentStartDate));
      if (filters.sentEndDate && filters.sentEndDate?.length > 0)
        queryParams.append("sentEndDate", (filters.sentEndDate));
      if (filters.responseReceivedStartDate && filters.responseReceivedStartDate?.length > 0)
        queryParams.append("responseReceivedStartDate", (filters.responseReceivedStartDate));
      if (filters.responseReceivedEndDate && filters.responseReceivedEndDate?.length > 0)
        queryParams.append("responseReceivedEndDate", (filters.responseReceivedEndDate));
      const queryString = queryParams.toString();
      const response = await axios.get(`${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/interaction-list?${queryString ? `${queryString}` : ""}`, Authorization_header());
      setInteractionData(response?.data?.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getAllInteractions();
  }, [detailedCase?.caseId]);


  return (
    <CaseContext.Provider
      value={{
        getAllCases,
        caseFilterState,
        fetchFilterProjectsList,
        filterCaseSurveysList,
        fetchSurveyList,
        setCaseFilterState,
        getSummaryListing,
        getAllInteractions,
        interactionFilterData,
        summaryData,
        isCaseFilterApplied,
        caseData,
        selectedCase,
        caseProjects,
        handleSelectedCase,
        getCaseById,
        detailedCase,
        currency,
        loading,
        getCaseOnLanding,
        setIsCaseFilterApplied,
        triggerCaseClearFilters,
        clearCaseFilterTrigger,
        caseSort,
        getCaseSortParams,
        caseFilterFields,
        caseSortFields
      }}
    >{children}
    </CaseContext.Provider>)
}