import { Box, Paper } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import TechnicalSummaryListing from './TechnicalSummaryListing';
import TechnicalSummaryDetail from './TechnicalSummaryDetail';
import { areFiltersApplied } from "../../../utils/helper/AreFiltersApplied";
import toast, { Toaster } from "react-hot-toast";
import { CaseContext } from '../../../context/CaseContext';

const TechnicalSummary = ({ usedfor, caseId, projectId, onApplyFilters }) => {
  const [showSummaryListingTab, setShowSummaryListingPage] = useState(true);
  const [TechnicalSummaryId, setTechnicalSummaryId] = useState("");
  const { caseFilterState, setCaseFilterState, triggerCaseClearFilters,
    setIsCaseFilterApplied } = useContext(CaseContext);
  const [page, setPage] = React.useState(0);
  const [filterClicked, setFilterClicked] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);


  const handleShowSummaryListing = () => {

    setShowSummaryListingPage(!showSummaryListingTab);
  }

  const getTechnicalSummaryId = (id = null) => {
    setTechnicalSummaryId(id);
  }

  useEffect(() => {
    setShowSummaryListingPage(true);
  }, [caseId, projectId]);


  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      // fetchProjects(filters);
    } else {
      toast.error("Please select at least one filter.");
    }
  };

  const appliedFilters = {
    company: caseFilterState.company,
  };

  const clearFilters = () => {
    if (page === "project") {
      setCaseFilterState({
        companyId: [],
        spocName: [],
        spockEmail: [],
        accountingYear: [],
        accYear: [],
        company: [],
        totalefforts: [0, null],
        rndExpense: [0, null],
        rndPotential: [0, null],
      });
      onApplyFilters({});
      triggerCaseClearFilters();
      setIsCaseFilterApplied(false);
    }
  };

  const handleFilterClick = () => {
    setFilterClicked(!filterClicked);
    setDrawerOpen(!drawerOpen);
    setFilterPanelOpen(!filterPanelOpen);
  };

  const handleFilterPanelClose = () => {
    setFilterPanelOpen(false);
    setTimeout(() => {
      setDrawerOpen(false);
      setFilterClicked(false);  // Reset the table movement after drawer closes
    }, 0);  // Timeout to match the drawer's closing transition duration
  };

  return (
    <>
      <Box>
        {showSummaryListingTab ? <TechnicalSummaryListing handleShowSummaryListing={handleShowSummaryListing} getTechnicalSummaryId={getTechnicalSummaryId}
          usedfor={usedfor}
          caseId={caseId}
          projectId={projectId}
          onApplyFilters={applyFiltersAndFetch}
          appliedFilters={appliedFilters}
         
        /> : <TechnicalSummaryDetail handleShowSummaryListing={handleShowSummaryListing} TechnicalSummaryId={TechnicalSummaryId} usedfor={usedfor} caseId={caseId} projectId={projectId} />}
      </Box>
    </>
  )
}

export default TechnicalSummary