import { CircularProgress, Table, TableContainer } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import TableHeader from "../Common/TableHeader";
import ProjectsTableBody from "../Projects/ProjectsTableBody";
import CustomPagination from "../Common/CustomPagination";
import { ProjectContext } from "../../context/ProjectContext";
import { formatFyscalYear } from "../../utils/helper/FormatFiscalYear";
import MiniTableHeader from "../Common/MiniTableHeader";

const tableData = {
    columns: [
        "Project Name",
        "Project ID",
        "Account",
        "Fiscal Year",
        "SPOC Name",
        "SPOC Email",
        "Total Expense",
        "R&D Expense",
        "R&D Potential (%)",
    ],
};

function TimesheetProject({ timesheetId, search }) {
    const {
        timesheetProject,
        fetchProjects,
        loading
    } = useContext(ProjectContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [filteredRows, setFilteredRows] = useState([]);
    const [projectSortParams, setProjectSortParams] = useState({ sortField: null, sortOrder: null })


    useEffect(() => {
        const options = {};
        if (timesheetId) {
            options.timesheetId = timesheetId;
        } 
        if (projectSortParams && projectSortParams?.sortField && projectSortParams?.sortOrder) {
            options.sortField = projectSortParams?.sortField;
            options.sortOrder = projectSortParams?.sortOrder;
        }
        
        fetchProjects(options);
    }, [timesheetId, projectSortParams]);

    const getProjectSortParams = ({ sortField, sortOrder }) => {
        setProjectSortParams({ sortField: sortField, sortOrder: sortOrder })
    }

    const totalPages = Math.ceil(filteredRows?.length / itemsPerPage);

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeItemsPerPage = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    const currentData = filteredRows?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    while (currentData?.length < itemsPerPage) {
        currentData?.push({});
    }

    useEffect(() => {
        if (timesheetProject) {
            const filteredData = timesheetProject?.filter(
                (task) =>
                    task?.projectName?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task?.projectId?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task.firstName?.toLowerCase().includes(search.toLowerCase()) ||
                    task.lastName?.toLowerCase().includes(search.toLowerCase()) ||
                    task.middleName?.toLowerCase().includes(search.toLowerCase()) ||
                    task.companyName?.toLowerCase().includes(search.toLowerCase()) ||
                    task?.description?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task?.projectCode?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task?.spocName?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task?.spocEmail?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task?.TotalExpense?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task?.rndExpense?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task?.rndPotential?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    formatFyscalYear(task?.accountingYear)?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    task?.companyId?.toString()?.includes(search) ||
                    task?.RnDExpenseCumulative?.toString()?.includes(search)
            );
            setFilteredRows(filteredData);
            setCurrentPage(1);
        }
    }, [timesheetProject, search]);

    return (
        <>
            <div style={{ borderRadius: "20px", overflow: "hidden", padding: "10px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        {/* <TableHeader tableData={tableData} page="project_details" /> */}
                        <MiniTableHeader tableData={tableData} fetchSortParams={getProjectSortParams} />
                        {!loading && <ProjectsTableBody
                            data={currentData}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                        />
                        }
                    </Table>
                    {loading && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "50px",
                                minHeight: "380px",
                            }}
                        >
                            <CircularProgress sx={{ color: "#00A398" }} />
                        </div>
                    )}

                    {currentData.length === 0 && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "50px",
                                minHeight: "380px",
                            }}
                        >
                            No projects found.
                        </div>
                    )}
                </TableContainer>
                <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    changePage={handleChangePage}
                    changeItemsPerPage={handleChangeItemsPerPage}
                />
                <Toaster />
            </div>
        </>
    );
}

export default TimesheetProject;
