import { Paper, Table, TableContainer, Box, Drawer, Badge } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import CustomPagination from "../../components/Common/CustomPagination";
import TableHeader2 from "../../components/Common/TableHeader2";
import TableIntro from "../../components/Common/TableIntro";
import DocumentsModal from "../../components/Documents/DocumentsModal";
import DocumentsTableBody from "../../components/Documents/DocumentsTableBody";
import { BaseURL } from "../../constants/Baseurl";
import { DocumentContext } from "../../context/DocumentContext";
import { FilterListContext } from "../../context/FiltersListContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { formattedDate } from "../../utils/helper/FormatDatetime";
import { getTimeDifference } from "../../utils/helper/UpdateTimeDifference";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import { Authorization_header, token_obj } from "../../utils/helper/Constant";
import usePinnedData from "../../components/CustomHooks/usePinnedData";
import { HiFilter } from "react-icons/hi";
import TimesheetFilters from "../../components/Timesheets/TimesheetFilter";
import ContactFilters from "../../components/Contacts/ContactFilters";
import DocumentFilters from "../../components/Documents/DocumentFilters";

const tableData = {
  columns: [

    "Document Name",
    "Account",
    "Category",
    "R&D Potential (%)",
    "Status",
    "Projects",
    "Uploaded On",
    "Uploaded By",
  ],
  rows: [
    {
      id: 1,
      name: "Attachment 01",
      category: "POC",
      relatedTo: "Project 1",
      uploadedOn: "12/12/2023",
      uploadedBy: "Prabhu Balakrishnan",
    },
    {
      id: 2,
      name: "Attachment 02",
      category: "POC",
      relatedTo: "Apple Inc.",
      uploadedOn: "19/12/2023",
      uploadedBy: "Prabhu Balakrishnan",
    },
    // ...add more rows as needed
  ],
};
const opacityValue = -1;
const styleConstants = {
  filterDownloadStyle: {
    color: "white",
    borderRadius: "50%",
    backgroundColor: "#00A398",
    fontSize: "28px",
    padding: "5px",
    marginRight: "16px",
    cursor: "pointer",
    // opacity: opacityValue,
  },
  tableContainerStyle: {
    borderLeft: "1px solid #E4E4E4",
    // backgroundColor: `rgba(255, 255, 255, ${opacityValue})`,  // Apply the same opacity to TableContainer
  },
  overlay: {
    // position: 'fixed',
    // top: 0,
    // left: 0,
    // width: '100%',
    // height: '100%',
    // backgroundColor: `rgba(0, 0, 0, ${opacityValue})`, // Adjust opacity as needed
    // zIndex: 1100, // Ensure it's above other content but below the drawer/modal
  },
  containerDimmed: {
    // opacity: -10.5,  // Adjust opacity as needed
    // transition: 'opacity 0s ease-in-out',  // Smooth transition
  },
};
const documentType = ["SOW", "Technical Documents"];

function Documents(page, onApplyFilters) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const { clientList, fetchClientList } = useContext(FilterListContext);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [documentData, setDocumentdata] = useState(null);
  const { pinnedObject } = usePinnedData();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [pinStates, setPinStates] = useState({
    "All Documents": false,
    "Recently Viewed": false,
  });

  const { documents, setCurrentState, fetchDocuments, docFilterState, currentState } =
    useContext(DocumentContext);
  const totalPages = Math.ceil(filteredRows?.length / itemsPerPage);

  const handleFilterClick = () => {
    setDrawerOpen(!drawerOpen);  // Only manage the drawer's open/close state here
    if (!drawerOpen) {
      setFilterPanelOpen(true);  // Ensure the filter panel is opened when the drawer is opened
    }
  };
  const handleFilterPanelClose = () => {
    setFilterPanelOpen(false);
    setDrawerOpen(false);  // Always close the drawer when the filter panel is closed
  };
  const handleFilterClose = () => {
    setFilterPanelOpen(false);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUploadClick = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const shouldFetchWithFilters =
      docFilterState.companyId.length > 0 ||
      docFilterState.projectId ||
      docFilterState.document;

    const options = {
      ...(docFilterState.companyId.length > 0 && { companyIds: docFilterState.companyId }),
      ...(docFilterState.projectId && { relationId: docFilterState.projectId }),
      ...(docFilterState.document && { documentType: docFilterState.document }),
    };

    fetchDocuments(shouldFetchWithFilters ? options : undefined);
  }, [currentState, Authorization_header]);

  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  const appliedFilters = {
    Clients: docFilterState.company,
    Projects: docFilterState.project,
    DocumentType: docFilterState.document,
    SortField: docFilterState.sortField,
    SortOrder: docFilterState.sortOrder
  };

  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters) || filters?.sortField || filters?.sortOrder) {
      fetchDocuments(filters);
    } else {
      fetchDocuments();
      toast.error("Please select at least one filter.");
    }
  };


  useEffect(() => {
    fetchClientList();
    setCurrentState(
      pinnedObject?.DOCUMENTS === "RV" ? "Recently Viewed" : "All Documents"
    );
  }, [Authorization_header]);
  useEffect(() => {
    const updatedPinStates = {
      "All Documents": pinnedObject.DOCUMENTS === "ALL",
      "Recently Viewed": pinnedObject.DOCUMENTS === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.DOCUMENTS]);


  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  let currentData = filteredRows?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Ensuring currentData always has 20 items
  const placeholderRow = {};
  while (currentData?.length < itemsPerPage) {
    currentData.push(placeholderRow);
  }

  const handleSearch = (input) => {
    setSearch(input);
  };

  useEffect(() => {
    if (documents) {
      const filteredData = documents?.filter(
        (task) => {
          return task?.relatedTo?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.documentName?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.documentType?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.createdBy?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.RnDScope?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.status?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.companyName?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.rd_score?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.aistatus?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            task?.projectName?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
            formattedDate(task?.createdTime)
              ?.toLowerCase()
              ?.includes(search?.toLowerCase())
        }
      );
      setFilteredRows(filteredData);
      setCurrentPage(1);
    }
  }, [documents, search]);

  useEffect(() => {
    const timeDifference = getTimeDifference(documents, "createdTime");
    setLatestUpdateTime(timeDifference);
  }, [documents]);

  const handleFormSubmit = async (formData) => {
    const apiUrl = `${BaseURL}/api/v1/documents/${localStorage.getItem(
      "userid"
    )}/upload-doc`;

    const formDataToSubmit = new FormData();
    formData.files?.forEach((file) => {
      formDataToSubmit.append("documents", file);
    });

    formDataToSubmit.append("companyId", formData.companyId);
    formDataToSubmit.append("relatedTo", formData.relatedTo);
    formDataToSubmit.append("relationId", formData.relationId);
    formDataToSubmit.append("docType", formData.doc);

    toast.loading("Uploading Documents...");
    try {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);

      const response = await axios.post(apiUrl, formDataToSubmit, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj?.accessToken}`
        },
      });
      setDocumentdata(response?.data?.data);
      fetchDocuments();
      handleModalClose();
      toast.dismiss();
      toast.success(response?.data?.message || "The file has been uploaded successfully and is currently in the processing queue. You'll receive a notification once processing is complete.");
    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message || "Failed to upload document.")
    }
  };

  const isUpload = useHasAccessToFeature("F029", "P000000002");
  const isSearch = useHasAccessToFeature("F029", "P000000009");

  const countActiveFilters = () => {
    let count = 0;

    if (docFilterState?.company?.length > 0 && docFilterState.company[0] !== "ALL") count += 1;
    if (docFilterState?.documentType?.length > 0) count += 1;
    if (docFilterState?.status?.some(phone => phone !== "")) count += 1;
    if (Array.isArray(docFilterState.uploadedBy) && docFilterState.uploadedBy.some(u => u !== "")) {
      count += 1;
    }
    return count;
  };

  return (
    <>
      <Box
        sx={{
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        {useHasAccessToFeature("F029", "P000000008") && (
          <Paper
            sx={{
              display: "flex",
              width: "98.5%",
              mx: "auto",
              mt: 1,
              flexDirection: "column",
              borderRadius: "20px",
              mb: 3,
              boxShadow: "0px 3px 6px #0000001F",
            }}
          >
            <TableIntro
              heading={"All Documents"}
              btnName={"Upload"}
              page={"document"}
              totalItems={filteredRows?.length || 0}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onUploadClick={handleUploadClick}
              onSearch={handleSearch}
              latestUpdateTime={latestUpdateTime?.difference}
              documentType={documentType}
              onApplyFilters={applyFiltersAndFetch}
              appliedFilters={appliedFilters}
              createPermission={isUpload}
              searchPermission={isSearch}
              onSelectedItem={handleSelectedHeaderItem}
            />
            <DocumentsModal
              open={modalOpen}
              handleClose={handleModalClose}
              handleSubmit={handleFormSubmit}
              type={"upload"}
              clients={clientList}
              docType={documentType}
              page="document"
            />
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              changePage={handleChangePage}
              changeItemsPerPage={handleChangeItemsPerPage}
              minRows={20}
            />
            <Box sx={{ display: "flex", pt: 0, pb: page === "activity" ? -1 : 0 }}>
              <Box sx={{ marginLeft: "9px", marginTop: "-120px", display: "flex", alignItems: "center" }}>
                {!(page === "alerts") && (
                  <Badge
                    badgeContent={countActiveFilters()}
                    color="error"
                    overlap="circular"
                    sx={{
                      zIndex: 2,
                      marginRight: "0px",
                      '& .MuiBadge-badge': {
                        minWidth: '10px',
                        height: '16px',
                        fontSize: '10px',
                        paddingLeft: '5',
                        transform: 'translate(25%, -25%)',
                        backgroundColor: '#FD5707',
                      },
                    }}
                  >
                    <HiFilter
                      style={styleConstants.filterDownloadStyle}
                      onClick={handleFilterClick}
                    />
                  </Badge>
                )}
              </Box>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleFilterPanelClose}
                sx={{
                  width: '300px',
                  flexShrink: 0,
                }}
                variant="persistent"
              >
                {filterPanelOpen && (
                  <DocumentFilters
                    handleClose={handleFilterPanelClose}
                    open={filterPanelOpen}
                    page={page}
                    documentType={documentType}
                    onApplyFilters={applyFiltersAndFetch}
                    style={{ position: 'absolute', left: 0 }}
                  />
                )}
              </Drawer>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                marginLeft: drawerOpen ? '300px' : '0',
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: "82vh",
                  overflowY: "auto",
                  borderTopLeftRadius: "20px",
                }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHeader2 tableData={tableData} page={"document"} />
                  <DocumentsTableBody data={currentData} />
                </Table>
              </TableContainer>
              {/* <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                changePage={handleChangePage}
                changeItemsPerPage={handleChangeItemsPerPage}
                minRows={20}
              /> */}
            </Box>
          </Paper>
        )}
        <Toaster />
      </Box >
    </>
  );
}

export default Documents;
