import React, { useContext, useEffect, useState } from 'react'
import CaseInteractionListing from "./CaseInteractionListing"
import CaseInteractionDetails from './CaseInteractionDetails';
import { areFiltersApplied } from "../../../utils/helper/AreFiltersApplied";
import toast, { Toaster } from "react-hot-toast";
import { CaseContext } from '../../../context/CaseContext';

const Interaction = ({ usedfor, caseId, projectId, onApplyFilters }) => {
    const [showIntercationListing, setShowInteractionListingPage] = useState(true);
    const [interactionId, setInteractionId] = useState();
    const [intrIndentifier, setIntrIndentifier] = useState();
    const { caseFilterState, setCaseFilterState, triggerCaseClearFilters,
        setIsCaseFilterApplied } = useContext(CaseContext);
    const [page, setPage] = React.useState(0);
    const [filterClicked, setFilterClicked] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filterPanelOpen, setFilterPanelOpen] = useState(false);

    const handleShowInteractionListing = () => {
        setShowInteractionListingPage(!showIntercationListing);
    }

    const handleInteractionId = (id = null, indentifier = null) => {
        setInteractionId(id);
        setIntrIndentifier(indentifier);
    }

    useEffect(() => {
        setShowInteractionListingPage(true);
    }, [caseId, projectId]);

    const applyFiltersAndFetch = (filters) => {
        if (areFiltersApplied(appliedFilters)) {
            // fetchProjects(filters);
        } else {
            toast.error("Please select at least one filter.");
        }
    };

    const appliedFilters = {
        company: caseFilterState.company,
    };

    const clearFilters = () => {
        if (page === "project") {
            setCaseFilterState({
                companyId: [],
                spocName: [],
                spockEmail: [],
                accountingYear: [],
                accYear: [],
                company: [],
                totalefforts: [0, null],
                rndExpense: [0, null],
                rndPotential: [0, null],
            });
            onApplyFilters({});
            triggerCaseClearFilters();
            setIsCaseFilterApplied(false);
        }
    };

    const handleFilterClick = () => {
        setFilterClicked(!filterClicked);
        setDrawerOpen(!drawerOpen);
        setFilterPanelOpen(!filterPanelOpen);
    };

    const handleFilterPanelClose = () => {
        setFilterPanelOpen(false);
        setTimeout(() => {
            setDrawerOpen(false);
            setFilterClicked(false);  // Reset the table movement after drawer closes
        }, 0);  // Timeout to match the drawer's closing transition duration
    };

    const handleFilterClose = () => {
        setFilterPanelOpen(false);
    };

    const countActiveFilters = () => {
        let count = 0;

        // Count selected companies if any
        if (caseFilterState?.company?.length > 0) count += 1;

        // Count selected accounting years if any
        if (caseFilterState?.accYear?.length > 0) count += 1;

        // Ensure spocName is an array and count non-empty names
        if (Array.isArray(caseFilterState?.spocName)) {
            if (caseFilterState.spocName.some(spocName => spocName?.trim() !== "")) {
                count += 1;
            }
        }

        // Ensure spocEmail is an array and count non-empty emails
        if (Array.isArray(caseFilterState?.spocEmail)) {
            if (caseFilterState.spocEmail.some(spocEmail => spocEmail?.trim() !== "")) {
                count += 1;
            }
        }

        // Count total expenses greater than 0
        if (Array.isArray(caseFilterState?.totalefforts)) {
            if (caseFilterState.totalefforts.some(expense => expense > 0)) {
                count += 1;
            }
        }

        // Count rnd expenses greater than 0
        if (Array.isArray(caseFilterState?.rndExpense)) {
            if (caseFilterState.rndExpense.some(expense => expense > 0)) {
                count += 1;
            }
        }

        // Count rnd potential greater than 0
        if (Array.isArray(caseFilterState?.rndPotential)) {
            if (caseFilterState.rndPotential.some(potential => potential > 0)) {
                count += 1;
            }
        }

        return count;
    };
    return (
        <>
            {showIntercationListing ? <CaseInteractionListing
                handleShowInteractionListing={handleShowInteractionListing}
                handleInteractionId={handleInteractionId}
                usedfor={usedfor}
                caseId={caseId}
                projectId={projectId}
                onApplyFilters={applyFiltersAndFetch}
                appliedFilters={appliedFilters}
            /> : <CaseInteractionDetails handleShowInteractionListing={handleShowInteractionListing} interactionId={interactionId} intrIndentifier={intrIndentifier} />}
        </>
    )
}

export default Interaction;