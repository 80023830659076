import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { BaseURL } from "../constants/Baseurl";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";
import CaseDetails from "../pages/cases/CasesDetails";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { pinnedObject } = usePinnedData();
  const [projects, setProjects] = useState([]);
  const [projectFilterState, setProjectFilterState] = useState({
    caseId: [],
    companyId: [],
    accountingYear: [],
    accYear: [],
    company: [],
    project: "",
    totalExpense: [0, null],
    rndExpense: [0, null],
    rndPotential: [0, null],
  });
  const [clearProjectFilterTrigger, setClearProjectFilterTrigger] = useState(false);
  const [isProjectFilterApplied, setIsProjectFilterApplied] = useState(false);
  const [sortParams, setSortPrams] = useState({ sortField: null, sortOrder: null });
  const [currentState, setCurrentState] = useState(
    pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
  );
  const [timesheetProject, setTimesheetProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();
  const [projectsFilterOptions, setProjectsFilterOptions] = useState({});
  const [projectsFilterFields, setProjectsFilterFields] = useState("");
  const [projectsSortFields, setProjectsSortFields] = useState("");
  const [detailedCase, setDetailedCase] = useState(null);

  // columns: [
  //   "Project Name",
  //   "Project ID",
  //   "Account",
  //   "Fiscal Year",
  //   "SPOC Name",
  //   "SPOC Email",
  //   "Total Expense",
  //   "R&D Expense",
  //   "R&D Potential (%)"
  // ],

  const getProjectsSortParams = ({ sortField, sortOrder }) => {
    // switch(sortField) 
    // {
    //   case "Project Name":
    //     sortField: "projectName";
    //     break;
    //   case ""
    // }


    setSortPrams({
      sortField,
      sortOrder
    })
  }

  const triggerProjectClearFilters = () => {
    setProjectFilterState({
      companyId: [],
      spocName: [],
      spocEmail: [],
      projectNames: [],
      projectCodes: [],
      company: "",
      project: "",
      totalExpense: [0, null],
      rndExpense: [0, null],
      rndPotential: [0, null],
    });
    setClearProjectFilterTrigger((prev) => !prev);
  };
  useEffect(() => {
    if (clearProjectFilterTrigger) {
      // fetchProjects();
    }
  }, [clearProjectFilterTrigger]);


  useEffect(() => {
    if (clearProjectFilterTrigger) {
      // fetchTimesheetProjects();
    }
  }, [clearProjectFilterTrigger]);

  function getAccessToken() {
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return token_obj?.accessToken || '';
  }

  useEffect(() => {
    getProjects();
  }, [sortParams])

  const ProjectSort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/a0ds/get-projects`;
    try {
      toast.loading("Fetching projects data");
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
      };
      const response = await axios.get(url, config)
      toast.dismiss();

      setProjects(response?.data?.data?.list);
      setProjectsFilterFields(response?.data?.data?.appliedFilter);
      setProjectsSortFields(response?.data?.data?.appliedSort);
      toast.success(response?.data?.message || "Succesfully fetched data");
    } catch (error) {
      toast.dismiss();
      toast.error("Error in fetching client data");
      console.error("Error in fetching client data : ", error);
    }
  }

  const getProjects = async () => {

    const queryParams = new URLSearchParams();

    if (projectsFilterOptions?.companyId || projectFilterState?.companyId?.length > 0)
      queryParams.append("companyIds", JSON.stringify(projectFilterState?.companyId || projectFilterState?.companyId));
    if (projectsFilterOptions.spocName || projectFilterState.spocName?.length > 0) {
      queryParams.append(
        "spocNames",
        JSON.stringify(projectsFilterOptions.spocName || projectFilterState.spocName)
      );
    }

    if (projectsFilterOptions.spocEmail || projectFilterState.spocEmail?.length > 0) {
      queryParams.append(
        "spocEmails",
        JSON.stringify(projectsFilterOptions.spocEmail || projectFilterState.spocEmail)
      );
    }
    if (projectsFilterOptions.accountingYear || projectFilterState?.accountingYear?.length > 0)
      queryParams.append(
        "fiscalYears",
        JSON.stringify(projectsFilterOptions.accountingYear || projectFilterState.accountingYear)
      );
    // Validate and append min/max values only if the user has interacted with them
    if (projectsFilterOptions.minTotalExpense != null && projectsFilterOptions.minTotalExpense > 0) {
      queryParams.append("minTotalExpense", projectsFilterOptions.minTotalExpense);
    }

    if (projectsFilterOptions.maxTotalExpense != null && projectsFilterOptions.maxTotalExpense < 2000000) {
      queryParams.append("maxTotalExpense", projectsFilterOptions.maxTotalExpense);
    }

    if (projectsFilterOptions.minRnDExpense != null && projectsFilterOptions.minRnDExpense > 0) {
      queryParams.append("minRnDExpense", projectsFilterOptions.minRnDExpense);
    }

    if (projectsFilterOptions.maxRnDExpense != null && projectsFilterOptions.maxRnDExpense < 2000000) {
      queryParams.append("maxRnDExpense", projectsFilterOptions.maxRnDExpense);
    }

    if (projectsFilterOptions.minRnDPotential != null && projectsFilterOptions.minRnDPotential > 0) {
      queryParams.append("minRnDPotential", projectsFilterOptions.minRnDPotential);
    }

    if (projectsFilterOptions.maxRnDPotential != null && projectsFilterOptions.maxRnDPotential < 100) {
      queryParams.append("maxRnDPotential", projectsFilterOptions.maxRnDPotential);
    }

    if (sortParams?.sortField && sortParams?.sortOrder) {
      queryParams.append("sortField", sortParams?.sortField);
      queryParams.append("sortOrder", sortParams?.sortOrder);
    }

    const queryString = queryParams.toString();

    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem(
      "userid"
    )}/a0ds/get-projects${queryString ? `?${queryString}` : ""}`;
    setIsProjectFilterApplied(queryString);
    try {
      const response = await axios.get(url, Authorization_header());
      setProjects(response?.data?.data?.list);
      setProjectsFilterFields(response?.data?.data?.appliedFilter);
      setProjectsSortFields(response?.data?.data?.appliedSort);
      if (projectsFilterOptions?.timesheetId) {
        setTimesheetProject(response?.data?.data?.list)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      console.error("Failed to fetch Projects:", error);
    }
  }

  const fetchProjects = async (options = {}) => {
    setProjectFilterState(options);
    setLoading(true);
    const queryParams = new URLSearchParams();

    if (options.companyId && options.companyId?.length > 0)
      queryParams.append("companyIds", JSON.stringify(options.companyId));

    // if (options.accountingYear || projectFilterState.accountingYear?.length > 0) {
    //   queryParams.append(
    //     "fiscalYears",
    //     JSON.stringify(options.accountingYear || projectFilterState.accountingYear)
    //   );
    // }

    if (options.spocName || projectFilterState.spocName?.length > 0) {
      queryParams.append(
        "spocNames",
        JSON.stringify(options.spocName || projectFilterState.spocName)
      );
    }

    if (options.spocEmail || projectFilterState.spocEmail?.length > 0) {
      queryParams.append(
        "spocEmails",
        JSON.stringify(options.spocEmail || projectFilterState.spocEmail)
      );
    }

    if (options.minTotalExpense != null && options.minTotalExpense > 0) {
      queryParams.append("minTotalExpense", options.minTotalExpense);
    }

    if (options.maxTotalExpense != null && options.maxTotalExpense) {
      queryParams.append("maxTotalExpense", options.maxTotalExpense);
    }

    if (options.minRnDExpense != null && options.minRnDExpense > 0) {
      queryParams.append("minRnDExpense", options.minRnDExpense);
    }

    if (options.maxRnDExpense != null && options.maxRnDExpense) {
      queryParams.append("maxRnDExpense", options.maxRnDExpense);
    }

    if (options.minRnDPotential != null) {
      queryParams.append("minRnDPotential", options.minRnDPotential);
    }

    if (options.maxRnDPotential != null) {
      queryParams.append("maxRnDPotential", options.maxRnDPotential);
    }
    if (options?.sortField != null && options?.sortField) {
      queryParams.append("sortField", options.sortField)
    }
    if (options?.sortOrder != null && options?.sortOrder) {
      queryParams.append("sortOrder", options.sortOrder)
    }
    if (options?.timesheetId !== null && options?.timesheetId) {
      queryParams.append("timesheetId", (options.timesheetId));
    }

    if (sortParams?.sortField && sortParams?.sortOrder) {
      queryParams.append("sortField", sortParams?.sortField);
      queryParams.append("sortOrder", sortParams.sortOrder);
    }


    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem(
      "userid"
    )}/a0ds/get-projects${queryString ? `?${queryString}` : ""}`;
    setIsProjectFilterApplied(queryString);

    try {
      const response = await axios.get(url, Authorization_header());
      setProjects(response?.data?.data?.list);
      setProjectsFilterFields(response?.data?.data?.appliedFilter);
      setProjectsSortFields(response?.data?.data?.appliedSort);
      if (options?.timesheetId) {
        setTimesheetProject(response?.data?.data?.list);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.logout === true ||
        error?.response?.data?.message === "session timed out"
      ) {
        toast.error("Session expired, you need to login again");
        logout();
      }
      console.error("Failed to fetch Projects:", error);
    }
  };

  const fetchCaseProjects = async (options = {}) => {
    setProjectFilterState(options);
    setLoading(true);
    const queryParams = new URLSearchParams();

    if (options.companyId && options.companyId?.length > 0)
      queryParams.append("companyIds", JSON.stringify(options.companyId));

    // if (options.accountingYear || projectFilterState.accountingYear?.length > 0) {
    //   queryParams.append(
    //     "fiscalYears",
    //     JSON.stringify(options.accountingYear || projectFilterState.accountingYear)
    //   );
    // }

    if (options.spocName || projectFilterState.spocName?.length > 0) {
      queryParams.append(
        "spocNames",
        JSON.stringify(options.spocName || projectFilterState.spocName)
      );
    }

    if (options.spocEmail || projectFilterState.spocEmail?.length > 0) {
      queryParams.append(
        "spocEmails",
        JSON.stringify(options.spocEmail || projectFilterState.spocEmail)
      );
    }

    if (options.minTotalExpense != null && options.minTotalExpense > 0) {
      queryParams.append("minTotalExpense", options.minTotalExpense);
    }

    if (options.maxTotalExpense != null && options.maxTotalExpense) {
      queryParams.append("maxTotalExpense", options.maxTotalExpense);
    }

    if (options.minRnDExpense != null && options.minRnDExpense > 0) {
      queryParams.append("minRnDExpense", options.minRnDExpense);
    }

    if (options.maxRnDExpense != null && options.maxRnDExpense) {
      queryParams.append("maxRnDExpense", options.maxRnDExpense);
    }

    // if (options.minRnDPotential != null) {
    //   queryParams.append("minRnDPotential", options.minRnDPotential);
    // }

    if (options.maxRnDPotential != null) {
      queryParams.append("maxRnDPotential", options.maxRnDPotential);
    }
    if (options?.sortField != null && options?.sortField) {
      queryParams.append("sortField", options.sortField)
    }
    if (options?.sortOrder != null && options?.sortOrder) {
      queryParams.append("sortOrder", options.sortOrder)
    }
    if (options?.timesheetId !== null && options?.timesheetId) {
      queryParams.append("timesheetId", JSON.stringify(options.timesheetId));
    }

    if (sortParams?.sortField && sortParams?.sortOrder) {
      queryParams.append("sortField", sortParams?.sortField);
      queryParams.append("sortOrder", sortParams.sortOrder);
    }


    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem(
      "userid"
    )}/a0ds/get-projects${queryString ? `?${queryString}` : ""}`;
    setIsProjectFilterApplied(queryString);

    try {
      const response = await axios.get(url, Authorization_header());
      setProjects(response?.data?.data?.list);
      setProjectsFilterFields(response?.data?.data?.appliedFilter);
      setProjectsSortFields(response?.data?.data?.appliedSort);
      if (options?.timesheetId) {
        setTimesheetProject(response?.data?.data?.list);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.logout === true ||
        error?.response?.data?.message === "session timed out"
      ) {
        toast.error("Session expired, you need to login again");
        logout();
      }
      console.error("Failed to fetch Projects:", error);
    }
  };

  const getCaseProjects = async () => {

    const queryParams = new URLSearchParams();

    // if (projectsFilterOptions?.caseId || projectFilterState?.caseId?.length > 0)
    //   queryParams.append("caseId", JSON.stringify(projectFilterState?.caseId || projectFilterState?.caseId));
    if (projectsFilterOptions?.companyId || projectFilterState?.companyId?.length > 0)
      queryParams.append("companyIds", JSON.stringify(projectFilterState?.companyId || projectFilterState?.companyId));
    if (projectsFilterOptions.spocName || projectFilterState.spocName?.length > 0) {
      queryParams.append(
        "spocNames",
        JSON.stringify(projectsFilterOptions.spocName || projectFilterState.spocName)
      );
    }

    if (projectsFilterOptions.spocEmail || projectFilterState.spocEmail?.length > 0) {
      queryParams.append(
        "spocEmails",
        JSON.stringify(projectsFilterOptions.spocEmail || projectFilterState.spocEmail)
      );
    }
    if (projectsFilterOptions.accountingYear || projectFilterState?.accountingYear?.length > 0)
      queryParams.append(
        "fiscalYears",
        JSON.stringify(projectsFilterOptions.accountingYear || projectFilterState.accountingYear)
      );
    // Validate and append min/max values only if the user has interacted with them
    if (projectsFilterOptions.minTotalExpense != null && projectsFilterOptions.minTotalExpense > 0) {
      queryParams.append("minTotalExpense", projectsFilterOptions.minTotalExpense);
    }

    if (projectsFilterOptions.maxTotalExpense != null && projectsFilterOptions.maxTotalExpense < 2000000) {
      queryParams.append("maxTotalExpense", projectsFilterOptions.maxTotalExpense);
    }

    if (projectsFilterOptions.minRnDExpense != null && projectsFilterOptions.minRnDExpense > 0) {
      queryParams.append("minRnDExpense", projectsFilterOptions.minRnDExpense);
    }

    if (projectsFilterOptions.maxRnDExpense != null && projectsFilterOptions.maxRnDExpense < 2000000) {
      queryParams.append("maxRnDExpense", projectsFilterOptions.maxRnDExpense);
    }

    if (projectsFilterOptions.minRnDPotential != null && projectsFilterOptions.minRnDPotential > 0) {
      queryParams.append("minRnDPotential", projectsFilterOptions.minRnDPotential);
    }

    if (projectsFilterOptions.maxRnDPotential != null && projectsFilterOptions.maxRnDPotential < 100) {
      queryParams.append("maxRnDPotential", projectsFilterOptions.maxRnDPotential);
    }

    if (sortParams?.sortField && sortParams?.sortOrder) {
      queryParams.append("sortField", sortParams?.sortField);
      queryParams.append("sortOrder", sortParams?.sortOrder);
    }

    const queryString = queryParams.toString();

    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem(
      "userid"
    )}/a0ds/get-projects?caseId=${detailedCase.caseId}${queryString ? `?${queryString}` : ""}`;
    setIsProjectFilterApplied(queryString);
    try {
      const response = await axios.get(url, Authorization_header());
      setProjects(response?.data?.data?.list);
      setProjectsFilterFields(response?.data?.data?.appliedFilter);
      setProjectsSortFields(response?.data?.data?.appliedSort);
      if (projectsFilterOptions?.timesheetId) {
        setTimesheetProject(response?.data?.data?.list)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      console.error("Failed to fetch Projects:", error);
    }
  };

  console.log("projectsFilterOptions", projectsFilterOptions);


  const fetchTimesheetProjects = async (options = {}) => {
    setProjectFilterState(options);
    setLoading(true);
    const queryParams = new URLSearchParams();

    if (options.companyId && options.companyId?.length > 0)
      queryParams.append("companyIds", JSON.stringify(options.companyId));

    if (options.projectNames && options.projectNames?.length > 0)
      queryParams.append("projectNames", JSON.stringify(options.projectNames));

    if (options.projectCodes && options.projectCodes?.length > 0)
      queryParams.append("projectCodes", JSON.stringify(options.projectCodes));

    if (options.spocName || projectFilterState.spocName?.length > 0) {
      queryParams.append(
        "spocNames",
        JSON.stringify(options.spocName || projectFilterState.spocName)
      );
    }

    if (options.spocEmail || projectFilterState.spocEmail?.length > 0) {
      queryParams.append(
        "spocEmails",
        JSON.stringify(options.spocEmail || projectFilterState.spocEmail)
      );
    }

    if (options.minTotalExpense != null && options.minTotalExpense > 0) {
      queryParams.append("minTotalExpense", options.minTotalExpense);
    }

    if (options.maxTotalExpense != null && options.maxTotalExpense) {
      queryParams.append("maxTotalExpense", options.maxTotalExpense);
    }

    if (options.minRnDExpense != null && options.minRnDExpense > 0) {
      queryParams.append("minRnDExpense", options.minRnDExpense);
    }

    if (options.maxRnDExpense != null && options.maxRnDExpense) {
      queryParams.append("maxRnDExpense", options.maxRnDExpense);
    }

    // if (options.minRnDPotential != null) {
    //   queryParams.append("minRnDPotential", options.minRnDPotential);
    // }

    // if (options.maxRnDPotential != null) {
    //   queryParams.append("maxRnDPotential", options.maxRnDPotential);
    // }
    if (options?.sortField != null && options?.sortField) {
      queryParams.append("sortField", options.sortField)
    }
    if (options?.sortOrder != null && options?.sortOrder) {
      queryParams.append("sortOrder", options.sortOrder)
    }
    // if (options?.timesheetId !== null && options?.timesheetId) {
    //   queryParams.append("timesheetId", (options.timesheetId));
    // }

    if (sortParams?.sortField && sortParams?.sortOrder) {
      queryParams.append("sortField", sortParams?.sortField);
      queryParams.append("sortOrder", sortParams.sortOrder);
    }


    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem(
      "userid"
    )}/a0ds/get-projects?timesheetId=${options?.timesheetId}${queryString ? `&${queryString}` : ""}`;
    setIsProjectFilterApplied(queryString);
    try {
      const response = await axios.get(url, Authorization_header());
      setProjects(response?.data?.data?.list);
      setProjectsFilterFields(response?.data?.data?.appliedFilter);
      setProjectsSortFields(response?.data?.data?.appliedSort);
      if (options?.timesheetId) {
        setTimesheetProject(response?.data?.data?.list);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.logout === true ||
        error?.response?.data?.message === "session timed out"
      ) {
        toast.error("Session expired, you need to login again");
        logout();
      }
      console.error("Failed to fetch Projects:", error);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        getProjects,
        timesheetProject,
        getCaseProjects,
        fetchProjects,
        fetchTimesheetProjects,
        fetchCaseProjects,
        getAccessToken,
        ProjectSort,
        projectFilterState,
        setProjectFilterState,
        triggerProjectClearFilters,
        clearProjectFilterTrigger,
        isProjectFilterApplied,
        setIsProjectFilterApplied,
        setCurrentState,
        currentState,
        loading,
        getProjectsSortParams,
        projectsFilterFields,
        projectsSortFields
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
