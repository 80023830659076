import AddIcon from "@mui/icons-material/Add";
import { Box, Button, InputAdornment, InputBase, Table, TableContainer } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BaseURL } from "../../constants/Baseurl";
import MiniTableHeader from "../Common/MiniTableHeader";
import UpdationDetails2 from "../Common/UpdationDetails2";
import ContactModal from "../Contacts/ContactModal";
import CompanyDetailsTableBody from "./CompanyDetailsTableBody";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import { Authorization_header } from "../../utils/helper/Constant";
import SearchIcon from "@mui/icons-material/Search";

const styleConstants = {
  inputStyle: {
    borderRadius: "20px",
    width: "30%",
    height: "40px",
    border: "1px solid #9F9F9F",
    mr: 2,
  },
  searchIconStyle: {
    color: "#9F9F9F",
    ml: "3px",
    mr: "-3px",
    width: "20px",
    height: "20px",
  },
}

const tableData = {
  columns: ["Employee Id","Employee Name", "Employee Title", "Employement Type", "Email Address"],
  rows: [
    {
      id: 1,
      name: "Adam Smith",
      title: "Finance Head",
      phone: "(336) 222-7000",
      email: "adam.smith@apple.com",
    },
  ],
};

function CompanyContacts({
  data,
  latestUpdateTime,
  modifiedBy,
  comId,
  fetchCompanyContacts,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [clientsData, setClientsData] = useState(null);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const handleSearchInputChange = (event) => {
    setSearch(event?.target?.value);
  }

  useEffect(() => {
    fetchCompanyContacts({ sortField, sortOrder });
  }, [sortField, sortOrder])

  useEffect(() => {
    const filteredData = data?.filter(task => (
      task?.firstName?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.lastName?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      (task?.firstName + " " + task?.lastName)?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.employeeTitle?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.phone?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.email?.toString().toLowerCase().includes(search?.toString().toLowerCase())
    ))
    setFilteredRows(filteredData);
  }, [search, data])

  const fetchSortParams = ({ sortField, sortOrder }) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  // const fetchCompanyData = async () => {
  //   try {
  //     const response1 = await axios.get(
  //       `${BaseURL}/api/v1/company/${localStorage.getItem(
  //         "userid"
  //       )}/get-company-list`
  //     );
  //     setClientsData(response1.data.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchCompanyData();
  // }, []);

  const addContact = async (contactInfo) => {
    const apiUrl = `${BaseURL}/api/v1/contacts/${localStorage.getItem(
      "userid"
    )}/${comId}/create-contact`;

    try {
      const response = await axios.post(apiUrl, contactInfo, Authorization_header());
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleAddContact = async (contactInfo) => {
    toast
      .promise(addContact(contactInfo), {
        loading: "Adding New Employee...",
        success: (data) => data?.message || "Employee added successfully",
        error: (error) =>
          error.response?.data?.error?.message || "Failed to add Employee.",
      })
      .then(() => {
        fetchCompanyContacts();
      })
      .catch((error) => {
        console.error("Employee addition failed:", error);
      });
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid #E4E4E4",
          p: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UpdationDetails2
            items={data?.length}
            latestUpdateTime={latestUpdateTime}
            modifiedBy={modifiedBy}
          />

          {useHasAccessToFeature("F011", "P000000007") && (
            <>
              <InputBase
                type="text"
                placeholder="Search..."
                onChange={handleSearchInputChange}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={styleConstants.searchIconStyle} />
                  </InputAdornment>
                }
                sx={styleConstants.inputStyle}
              />
              <Button
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  backgroundColor: "#00A398",
                  color: "white",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "#00A398",
                  },
                }}
                onClick={() => setModalOpen(!modalOpen)}
              >
                <AddIcon style={{ fontSize: "17px", marginRight: "3px" }} />
                New Employee
              </Button>
            </>
          )}
          <ContactModal
            open={modalOpen}
            handleClose={handleModalClose}
            onAddContact={handleAddContact}
            clients={clientsData}
          />
        </Box>
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            maxHeight: "50vh",
          }}
        >
          <Table aria-label="simple table" stickyHeader>
            <MiniTableHeader tableData={tableData} fetchSortParams={fetchSortParams} />
            <CompanyDetailsTableBody filledRows={filteredRows} />
          </Table>
        </TableContainer>
      </Box>
      <Toaster />
    </>
  );
}

export default CompanyContacts;
