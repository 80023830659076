import React, { useEffect, useState } from "react";
import { Box, Button, InputAdornment, InputBase, Table, TableContainer } from "@mui/material";
import UpdationDetails2 from "../Common/UpdationDetails2";
import AddIcon from "@mui/icons-material/Add";
import MiniTableHeader from "../Common/MiniTableHeader";
import ContactsProjectsTableBody from "./ContactsProjectsTableBody";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import SearchIcon from "@mui/icons-material/Search";

const styleConstants = {
  inputStyle: {
    borderRadius: "20px",
    width: "30%",
    height: "40px",
    border: "1px solid #9F9F9F",
    mr: 2,
  },
  searchIconStyle: {
    color: "#9F9F9F",
    ml: "3px",
    mr: "-3px",
    width: "20px",
    height: "20px",
  },
}

const tableData = {
  columns: ["Project Name", "Project ID", "Role", "Employee Title"],
  rows: [
    {
      id: 1,
      projectId: "000000000011258",
      role: "Finance Head",
      startDate: Date.now(),
      endDate: Date.now(),
    },
    {
      id: 2,
      projectId: "000000000011258",
      role: "Finance Head",
      startDate: Date.now(),
      endDate: Date.now(),
    },
  ],
};

function ProjectsTab({ data, modifiedBy, latestUpdateTime, getProjectsSortParams }) {
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    const filteredData = data?.filter(task => (
      task?.projectName?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.projectCode?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.projectId?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.projectRole?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.employeeTitle?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) 
      // task?.totalCosts?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      // task?.rndExpense?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      // task?.rndPotential?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) 
      // formatFyscalYear(task?.accountingYear)?.toString().toLowerCase().includes(search?.toString()?.toLowerCase())
    ))
    setFilteredRows(filteredData);
  }, [search, data])

  const handleSearchInputChange = (event) => {
    setSearch(event?.target?.value);
  }
  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid #E4E4E4",
          p: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UpdationDetails2
            items={data?.length}
            latestUpdateTime={latestUpdateTime}
            modifiedBy={modifiedBy}
          />
          <InputBase
            type="text"
            placeholder="Search..."
            onChange={handleSearchInputChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={styleConstants.searchIconStyle} />
              </InputAdornment>
            }
            sx={styleConstants.inputStyle}
          />
          {/* {useHasAccessToFeature("F035", "P000000007") && (
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "20px",
                backgroundColor: "#00A398",
                color: "white",
                mr: 2,
                "&:hover": {
                  backgroundColor: "#00A398",
                },
              }}
            >
              <AddIcon style={{ fontSize: "17px", marginRight: "3px" }} />
              Assign
            </Button>
          )} */}
        </Box>
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            maxHeight: "50vh",
          }}
        >
          <Table aria-label="simple table" stickyHeader>
            <MiniTableHeader tableData={tableData} fetchSortParams={getProjectsSortParams} />
            <ContactsProjectsTableBody filledRows={filteredRows} />
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default ProjectsTab;
