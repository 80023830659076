import { Box, InputAdornment, InputBase, Table, TableContainer, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import UpdationDetails2 from "../Common/UpdationDetails2";
import PanelTableHeader from "../Timesheets/MainPanel/PanelTableHeader";
import MiniTimesheetTableBody from "./MiniTimesheetTableBody";
import SearchIcon from "@mui/icons-material/Search";
import FormatDatetime from "../../utils/helper/FormatDatetime";


const styleConstants = {
  inputStyle: {
    borderRadius: "20px",
    width: "30%",
    height: "40px",
    border: "1px solid #9F9F9F",
    mr: 2,
  },
  searchIconStyle: {
    color: "#9F9F9F",
    ml: "3px",
    mr: "-3px",
    width: "20px",
    height: "20px",
  },
}

const tableData = {
  columns: [
    // "",
    "Timesheet",
    "Month",
    "Status",
    "Uploaded On",
    "Total Hours"
    // "Workbench ID",
    // "R&D Hours",
    // "R&D Expense",
    // "Uncertain Hours",
    // "Reconciled Hours",
  ],
  rows: [
    {
      id: 1,
      month: "Nov 2023",
      status: "Pending",
      timesheet: "",
      uploadedOn: "",
      uploads: "",
      rndHours: "",
      rndExpense: "",
      uncertainHrs: "",
      reconciledHrs: "",
    },
    {
      id: 2,
      month: "Oct 2023",
      status: "Uploaded",
      timesheet: "TS_Oct23",
      uploadedOn: "28/10/2023",
      uploads: "1",
      rndHours: "285",
      rndExpense: "$1300",
      uncertainHrs: "26",
      reconciledHrs: "0",
    },
    {
      id: 3,
      month: "Sep 2023",
      status: "Uploaded",
      timesheet: "TS_Sep23",
      uploadedOn: "29/09/2023",
      uploads: "3",
      rndHours: "456",
      rndExpense: "$1729",
      uncertainHrs: "0",
      reconciledHrs: "42",
    },
    {
      id: 4,
      month: "Aug 2023",
      status: "Uploaded",
      timesheet: "TS_Aug23",
      uploadedOn: "26/08/2023",
      uploads: "1",
      rndHours: "231",
      rndExpense: "$982",
      uncertainHrs: "0",
      reconciledHrs: "56",
    },
  ],
};

function Timesheet({ data, latestUpdateTime, modifiedBy, getTimeSheetSortParams }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredProjectTimeheet, setFilteredProjectTimeheet] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredRow, setFilteredRows] = useState([]);

  useEffect(() => {
    const filteredData = data?.filter(task => (
      task?.timesheetMonth?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.timesheetYear?.toString()?.trim()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.status?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.originalFileName?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      FormatDatetime(task?.uploadedOn)?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.totalHours?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) 
    ))
    setFilteredRows(filteredData);
  }, [search, data])

  const handleSearchInputChange = (event) => {
    setSearch(event?.target?.value);
  }



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (filteredRow) {
      const filteredData = filteredRow?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      setFilteredProjectTimeheet(filteredData);
    }
  }, [filteredRow, page, rowsPerPage]);

  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid #E4E4E4",
          p: 1,
          display: "flex", justifyContent: "space-between"
        }}
      >
        <UpdationDetails2
          items={data?.length}
          latestUpdateTime={latestUpdateTime}
          modifiedBy={modifiedBy}
        />

        <InputBase
          type="text"
          placeholder="Search..."
          onChange={handleSearchInputChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={styleConstants.searchIconStyle} />
            </InputAdornment>
          }
          sx={styleConstants.inputStyle}
        />
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            maxHeight: "50vh",
          }}
        >
          <Table stickyHeader aria-label="simple table">
            <PanelTableHeader tableData={tableData} fetchSortParams={getTimeSheetSortParams} />
            <MiniTimesheetTableBody filledRows={filteredProjectTimeheet} rowsPerPage={rowsPerPage} />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}

export default Timesheet;
