import React, { useEffect, useState } from "react";
import { Box, Button, InputAdornment, InputBase, Table, TableContainer, TablePagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UpdationDetails2 from "../Common/UpdationDetails2";
import AddIcon from "@mui/icons-material/Add";
import MiniTableHeader from "../Common/MiniTableHeader";
import TeamTableBody from "./TeamTableBody";
import TeamModal from "./Team/TeamModal";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";

const styleConstants = {
  inputStyle: {
    borderRadius: "20px",
    width: "30%",
    height: "40px",
    border: "1px solid #9F9F9F",
    mr: 2,
  },
  searchIconStyle: {
    color: "#9F9F9F",
    ml: "3px",
    mr: "-3px",
    width: "20px",
    height: "20px",
  },
}

const tableData = {
  columns: [
    "Name",
    "Role",
    "Hourly Rate",
    "Total Hours",
    "Total Expense",
    "R&D Expense",
  ],
  rows: [
    {
      id: 1,
      name: "Adam Smith",
      title: "Finance Head",
      role: "Finance Head",
      company: "Apple Inc.",
      status: "Active",
      startDate: "01/01/2023",
      endDate: "-",
      phone: "(336)-222-7000",
      email: "adamsmith@apple.com",
    },
  ],
};

function Team({
  data,
  latestUpdateTime,
  modifiedBy,
  fetchTeamMembers,
  details,
  symbol,
  getTeamSortParams
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredProjectTeam, setFilteredProjectTeam] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredRow, setFilteredRows] = useState([]);

  useEffect(() => {
    const filteredData = data?.filter(task => (
      task?.contactFirstName?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.contactLastName?.toString()?.trim()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.contactTitle?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.hourlyRate?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.totalHours?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.rndHours?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.rndCost?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.employeeTitle?.toString().toLowerCase().includes(search?.toString()?.toLowerCase())
      // task?.totalCosts?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      // task?.rndExpense?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      // task?.rndPotential?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) 
      // formatFyscalYear(task?.accountingYear)?.toString().toLowerCase().includes(search?.toString()?.toLowerCase())
    ))
    setFilteredRows(filteredData);
  }, [search, data])

  const handleSearchInputChange = (event) => {
    setSearch(event?.target?.value);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  useEffect(() => {
    if (filteredRow) {
      const filteredData = filteredRow?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      setFilteredProjectTeam(filteredData);
    }
  }, [filteredRow, page, rowsPerPage]);

  return (
    <>
      <Box
        sx={{
          borderTop: "1px solid #E4E4E4",
          p: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UpdationDetails2
            items={data?.length}
            latestUpdateTime={latestUpdateTime}
            modifiedBy={modifiedBy}
          />

          <InputBase
            type="text"
            placeholder="Search..."
            onChange={handleSearchInputChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={styleConstants.searchIconStyle} />
              </InputAdornment>
            }
            sx={styleConstants.inputStyle}
          />

          {useHasAccessToFeature("F017", "P000000007") && (
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "20px",
                backgroundColor: "#00A398",
                color: "white",
                mr: 2,
                "&:hover": {
                  backgroundColor: "#00A398",
                },
              }}
              onClick={() => setModalOpen(!modalOpen)}
            >
              <AddIcon style={{ fontSize: "17px", marginRight: "3px" }} />
              New Team Member
            </Button>
          )}
          <TeamModal
            fetchTeamMembers={fetchTeamMembers}
            open={modalOpen}
            handleClose={handleModalClose}
            details={details}
          />
        </Box>
      </Box>
      <Box>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            // maxHeight: "50vh",
            height: 300
          }}
        >
          <Table stickyHeader aria-label="simple table">
            <MiniTableHeader tableData={tableData} fetchSortParams={getTeamSortParams} />
            <TeamTableBody filledRows={filteredProjectTeam} rowsPerPage={rowsPerPage} symbol={symbol} />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}

export default Team;
