
import { Box, Button, InputAdornment, InputBase, Table, TableContainer } from "@mui/material";
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { GoUpload } from "react-icons/go";

import { BaseURL } from "../../constants/Baseurl";
import { FilterListContext } from "../../context/FiltersListContext";
import { DocumentContext } from "../../context/DocumentContext";
import MiniTableHeader from "../Common/MiniTableHeader";
import UpdationDetails2 from "../Common/UpdationDetails2";
import DocumentsModal from "../Documents/DocumentsModal";
import DocumentTableBody from "./DocumentTableBody";
import { token_obj } from "../../utils/helper/Constant";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import SearchIcon from "@mui/icons-material/Search";
import { formatFyscalYear } from "../../utils/helper/FormatFiscalYear";
import { formattedDate } from "../../utils/helper/FormatDatetime";

const tableData = {
  columns: [/*"Document Type",*/ "Document Name", "Document Type", "Status", "Uploaded Date", "Uploaded By"],
  rows: [
    {
      name: "Attachment 01",
      category: "POC",
      uploadedOn: "18/11/2023",
      uploadedBy: "Prabhu Balakrishnan",
    },
  ],
};

const styleConstants = {
  inputStyle: {
    borderRadius: "20px",
    width: "30%",
    height: "40px",
    border: "1px solid #9F9F9F",
    mr: 2,
  },
  searchIconStyle: {
    color: "#9F9F9F",
    ml: "3px",
    mr: "-3px",
    width: "20px",
    height: "20px",
  },
}

const documentType = ["SOW", "Technical Documents"];

function Documents({
  data,
  page,
  comId,
  comName,
  projId,
  projName,
  onClientDocumentUploadSuccess,
  onProjectDocumentUploadSuccess,
  latestUpdateTime,
  modifiedBy,
  
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [search, setSearch] = useState("");
  const [filterRows, setFilterRows] = useState([]);
  const { clientList } = useContext(FilterListContext);
  const { documents, fetchDocuments, getDocumentsSortParams } = useContext(DocumentContext);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    // Ensure comId and projId are not null or undefined
    if (comId && projId) {
      const companyIds = [comId.toString()];
      const relationId = projId.toString();
      fetchDocuments({ companyIds, relatedTo: "projects", relationId });
    }
  }, [comId, projId]);

  

  useEffect(() => {
    const filteredData = data?.filter(task => (
      task?.documentName?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      task?.aistatus?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase()) ||
      formattedDate(task?.createdTime)?.toString()?.toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      task?.createdBy?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) 
      // task?.spocName?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      // task?.totalCosts?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      // task?.rndExpense?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      // task?.rndPotential?.toString().toLowerCase().includes(search?.toString()?.toLowerCase()) ||
      // formatFyscalYear(task?.accountingYear)?.toString().toLowerCase().includes(search?.toString()?.toLowerCase())
    ))
    setFilterRows(filteredData);
  }, [search, data])

  const handleSearchInputChange = (event) => {
    setSearch(event?.target?.value);
  }

  // useEffect(() => {
  //   fetchDocuments({
  //     companyIds: [companyId?.toString()],
  //     relatedTo: "projects",
  //     relationId: projectId?.toString(),
  //   });
  //   setShouldRefetch(false);
  // }, [projectId, shouldRefetch]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUploadClick = () => {
    setModalOpen(true);
  };

  const handleDocumentUploadSuccess = () => {
    if (page === "account") {
      onClientDocumentUploadSuccess();
    } else {
      onProjectDocumentUploadSuccess();
    }
  };

  const handleFormSubmit = async (formData) => {
    const apiUrl = `${BaseURL}/api/v1/documents/${localStorage.getItem("userid")}/upload-doc`;

    const formDataToSubmit = new FormData();
    formData?.files?.forEach((file) => {
      formDataToSubmit.append("documents", file);
    });
    formDataToSubmit.append("companyId", formData.companyId);
    formDataToSubmit.append("relatedTo", formData.relatedTo);
    formDataToSubmit.append("relationId", formData.relationId);
    formDataToSubmit.append("docType", formData.doc);

    toast.loading("Uploading Documents...");
    try {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);

      const response = await axios.post(apiUrl, formDataToSubmit, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj?.accessToken}`
        },
      });

      setDocumentData(response?.data?.data);
      // Refetch documents with updated companyIds and relationId
      fetchDocuments({ companyIds: [formData.companyId], relatedTo: "projects", relationId: formData.relationId });
      handleModalClose();
      toast.dismiss();
      toast.success(response?.data?.message || "The file has been uploaded successfully.");
    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message || "Failed to upload document.");
    }
  };

  return (
    <>
      <Box sx={{ borderTop: "1px solid #E4E4E4", p: 1, display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UpdationDetails2
            items={data?.length}
            latestUpdateTime={latestUpdateTime}
            modifiedBy={modifiedBy}
          />
          <InputBase
            type="text"
            placeholder="Search..."
            onChange={handleSearchInputChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={styleConstants.searchIconStyle} />
              </InputAdornment>
            }
            sx={styleConstants.inputStyle}
          />
          {useHasAccessToFeature("F021", "P000000002") && (
            <Button
              sx={{
                textTransform: "capitalize",
                borderRadius: "20px",
                backgroundColor: "#00A398",
                color: "white",
                mr: 2,
                "&:hover": {
                  backgroundColor: "#00A398",
                },
              }}
              onClick={handleUploadClick}
            >
              <GoUpload style={{ fontSize: "17px", marginRight: "3px" }} />
              Upload
            </Button>
          )}
        </Box>
        <DocumentsModal
          open={modalOpen}
          handleClose={handleModalClose}
          type={"upload"}
          clients={clientList}
          docType={documentType}
          handleSubmit={handleFormSubmit}
          page={page}
          comId={comId}
          comName={comName}
          projId={projId}
          projName={projName}
        />
      </Box>
      <Box>
        <TableContainer sx={{ width: "100%", overflowX: "auto", maxHeight: "50vh" }}>
          <Table stickyHeader aria-label="simple table">
            <MiniTableHeader tableData={tableData} fetchSortParams={getDocumentsSortParams} />
            <DocumentTableBody filledRows={filterRows} />
          </Table>
        </TableContainer>
      </Box>
      <Toaster />
    </>
  );
}

export default Documents;